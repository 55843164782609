import React from "react";
import { Alert, Card, Form, Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CancelButton from "../../components/CancelButton";
import Layout, { RoutineSettingsBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import RoutinePreview from "../../components/RoutinePreview";
import TextFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import TimeFormGroup from "../../components/FormGroups/TextFormGroup.jsx";
import CheckFormGroup from "../../components/FormGroups/CheckFormGroup.jsx";
import SelectFormGroup from "../../components/FormGroups/SelectFormGroup.jsx";
import SwitchFormGroup from "../../components/FormGroups/SwitchFormGroup.jsx";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
var cronstrue = require("cronstrue/i18n");

export default function RoutineSettingsPage() {
    const { organizationId, locationId, routineId } = useParams();
    const history = useHistory();
    const [tasks, setTasks] = React.useState([]);
    const [droneIds, setDroneIds] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);
    const [location, setLocation] = React.useState(null);

    const { getUserIsAdmin } = useUser();

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    const createRoutineSchema = () => z.object({
        name: z.string().min(1, { message: "Enter a name" }),
        enabled: z.boolean(),
        advanced: z.boolean(),
        time: z.string().refine((value) => {
            try {
                if (value === null || value.length === 0) {
                    return true;
                }

                const splitTime = value.split(":");
                const hour = +splitTime[0];
                const minute = +splitTime[1];
                const validHour = 0 <= hour <= 23 && Math.floor(hour) === hour;
                const validMinute = 0 <= minute <= 23 && Math.floor(minute) === minute;
                return validHour && validMinute;
            } catch (e) {
                return false;
            }
        }),
        days: z.array(z.boolean()).optional(),
        interval: z.string().refine((value) => {
            try {
                cronstrue.toString(value, { locale: "en" });
                return true; // The cron string is valid
            } catch (e) {
                return false; // The cron string is invalid
            }
        }, {
            message: "Invalid crontab expression", // Custom error message
        }),
        taskId: z.string().min(1, { message: "Select a task" }),
        droneId: z.string().min(1, { message: "Select a drone" }).refine((val) => droneIds.includes(val), { message: "This drone is not available" }),
        missionId: z.string()
    });

    const { control, handleSubmit, setError, clearErrors, reset, watch, setValue, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(createRoutineSchema())
    });

    const routine = watch();

    React.useEffect(() => {
        console.log(routine.time, routine.days, routine.advanced, (routine.time && !routine.advanced));
        if (routine.time && !routine.advanced) {
            const splitTime = routine.time.split(":");
            if (!(splitTime[1] && splitTime[0])) {
                splitTime[0] = "00";
                splitTime[1] = "00"
            }
            const days = (routine.days.every((x) => x) || routine.days.every((x) => !x)) ? '*' : routine.days.reduce(
                (accumulator, currentValue, currentIndex) => currentValue ? (accumulator + "," + (currentIndex + 1)) : accumulator,
                "",
            ).slice(1);
            const intervalString = `${splitTime[1]} ${splitTime[0]} * * ${days}`;
            console.log(intervalString);
            setValue('interval', intervalString);
        } else if (routine.advanced) {
            setValue('time', '');
        }
    }, [routine.time, routine.days, routine.advanced, setValue]);

    React.useEffect(() => {
        if (routine.time && routine.days && routine.advanced) {
            // we switch from simple to advanced, so move the filled in time to the interval string
            const splitTime = routine.time.split(":");
            const days = routine.days.every((x) => x) ? '*' : routine.days.reduce(
                (accumulator, currentValue, currentIndex) => currentValue ? (accumulator + "," + (currentIndex + 1)) : accumulator,
                "",
            ).slice(1);
            const intervalString = `${splitTime[1]} ${splitTime[0]} * * ${days}`;
            setValue('interval', intervalString);
            setValue('time', "");
            setValue('days', []);
        } else if (!routine.advanced && routine.interval != null && routine.interval.length > 0) {
            // we switched to the simple version, if the cron string fits, fill in the simple version with it.
            let hours = routine.interval.split(' ')[1];
            let minutes = routine.interval.split(' ')[0];

            if ((/^\d+$/.test(hours) && hours.length <= 2) && (/^\d+$/.test(minutes) && minutes.length <= 2)) {
                setValue('time', hours + ":" + minutes);
            } else {
                setValue('time', "");
            }

            let days = routine.interval.split(' ')[4];
            if (days === '*') {
                setValue('days', [true, true, true, true, true, true, true]);
            }
            if (/^[0-9,]+$/.test(days) && days !== ',') {
                let newDays = [false, false, false, false, false, false, false];
                days = days.split(",").map((x) => +x).filter((x) => x < (newDays.length + 1));
                for (let selectedDay in days) {

                    newDays[days[selectedDay] - 1] = true;
                }
                setValue('days', newDays);

            }
            setValue('interval', "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routine.advanced, setValue]);

    React.useEffect(() => {
        if (routineId) {
            setIsLoading(true);
            api.getRoutine(organizationId, locationId, routineId, (routine) => {
                routine.missionId = routine.missionId || '';

                routine.advanced = routine.advanced == null || routine.advanced;
                routine.days = routine.days == null ? [false, false, false, false, false, false, false] : routine.days;
                routine.time = routine.time == null ? "" : routine.time;

                routine.droneId = droneIds.includes(routine.droneId) ? routine.droneId : '';
                routine.taskId = tasks.map((task) => task.id).includes(routine.taskId) ? routine.taskId : '';

                reset(routine);
                setIsLoading(false);
            }).catch(error => {
                console.error("Error fetching data:", error);
                setHasError(true);
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(true);
            reset(
                {
                    name: '',
                    enabled: false,
                    advanced: false,
                    time: '',
                    days: [false, false, false, false, false, false, false],
                    interval: '',
                    taskId: '',
                    droneId: '',
                    missionId: ''
                }
            )
            setIsLoading(false);
        }
    }, [organizationId, locationId, routineId, tasks, droneIds, reset]);

    React.useEffect(() => {
        api.getTasks(organizationId, locationId, setTasks);
        api.getDroneIds(organizationId, locationId, setDroneIds);
    }, [organizationId, locationId]);

    function isNewRoutine() {
        return routineId === undefined;
    }

    const onSubmit = async (formData) => {
        console.log(formData.days);
        let missionIdIsValid = true;
        if (formData.missionId.length > 0) {
            missionIdIsValid = await api.checkMissionIdExists(organizationId, locationId, formData.missionId);
        }
        if (!missionIdIsValid) {
            setError('missionId', { type: 'manual', message: 'This mission id does not exist' });
        } else {
            clearErrors('missionId');

            // generate a interval, if its not provided and the time and days are provided
            if ((!formData.interval || formData.interval.length === 0) && formData.time && formData.time.length > 0) {
                // generate the cron tab string
                const splitTime = formData.time.split(":");
                const days = formData.days.every((x) => x) ? '*' : formData.days.reduce(
                    (accumulator, currentValue, currentIndex) => currentValue ? accumulator + "," + currentIndex : accumulator,
                    "",
                );
                const intervalString = `${splitTime[1]} ${splitTime[0]} * * ${days}`;
                formData.interval = intervalString;
            }

            if (isNewRoutine()) {
                return api.createRoutine(organizationId, locationId, formData)
                    .then(() => {
                        history.push(getBackUrl());
                    });
            } else {
                return api.saveRoutine(organizationId, locationId, routineId, formData)
                    .then(() => {
                        history.push(getBackUrl());
                    });
            }
        }
    }

    function getBackUrl() {
        if (isNewRoutine()) {
            return urls.routines(organizationId, locationId);
        } else {
            return urls.routine(organizationId, locationId, routineId);
        }
    }

    function getTitle() {
        if (isNewRoutine()) {
            return "Create Routine";
        } else {
            return "Routine Settings";
        }
    }

    return (
        <Layout>
            <RoutineSettingsBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={getBackUrl()} />
                        {getTitle()}
                    </>
                } />
                < Card.Body className="p-4 py-0">
                    {hasError ? (
                        <Alert variant="danger" className="mx-4 mb-4">
                            Something went wrong. Please refresh the page.
                        </Alert>) :
                        isLoading ? (
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (
                            <>
                                <RoutinePreview className='mb-4'
                                    organizationId={organizationId}
                                    locationId={locationId}
                                    location={location}
                                    routine={routine}
                                />
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <TextFormGroup label="Name"
                                        name="name"
                                        control={control}
                                        errors={errors} />
                                    {getUserIsAdmin() &&
                                        (
                                            <SwitchFormGroup
                                                label="Advanced"
                                                name="advanced"
                                                checked={routine.advanced}
                                                control={control}
                                                errors={errors} />
                                        )
                                    }
                                    {!routine.advanced &&
                                        (
                                            <TimeFormGroup label="Time"
                                                name="time"
                                                placeholder="HH:MM"
                                                control={control}
                                                errors={errors} />
                                        )
                                    }
                                    {!routine.advanced &&
                                        (
                                            <CheckFormGroup label="Days"
                                                name="days"
                                                labels={["M", " T ", " W ", " T ", " F ", " S ", " S "]}
                                                options={["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saterday", "Sunday"]}
                                                control={control}
                                                errors={errors}
                                            />
                                        )
                                    }
                                    {getUserIsAdmin() && routine.advanced &&
                                        (
                                            <TextFormGroup label="Interval"
                                                name="interval"
                                                control={control}
                                                errors={errors} />
                                        )
                                    }

                                    <SelectFormGroup label="Task"
                                        name="taskId"
                                        options={tasks}
                                        disabledOption={{ name: 'Select a task...' }}
                                        control={control}
                                        errors={errors} />
                                    <SelectFormGroup label="Drone"
                                        name="droneId"
                                        options={droneIds.map(droneId => ({ id: droneId, name: droneId }))}
                                        disabledOption={{ name: 'Select a drone...' }}
                                        control={control}
                                        errors={errors} />
                                    <TextFormGroup label="Continue with mission"
                                        name="missionId"
                                        placeholder="Enter mission id or leave empty to create new missions"
                                        control={control}
                                        errors={errors} />
                                    <SwitchFormGroup label="Enabled"
                                        name="enabled"
                                        checked={routine.enabled}
                                        control={control}
                                        errors={errors} />
                                    <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                                        <FaSave className="mb-1 me-1" />
                                        {isSubmitting ? "Saving..." : "Save"}
                                    </Button>
                                    <CancelButton
                                        className="ms-2 mb-3 mt-1"
                                        href={getBackUrl()}
                                    />
                                </Form>
                            </>)}
                </Card.Body>
            </Card>
        </Layout>
    );
}
