import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import * as api from "../../api/api";

const TimeDisplay = ({ timestamp, format, organizationId, locationId, location: providedLocation }) => {
    const [location, setLocation] = useState(providedLocation);

    useEffect(() => {
        if (!providedLocation) {
            return api.subscribeLocation(organizationId, locationId, setLocation);
        }
    }, [organizationId, locationId, providedLocation]);

    const formatTimestamp = (timezone) => {
        return moment(timestamp * 1000).tz(timezone).format(format);
    };

    const userTimezone = moment.tz.guess();
    const localTimezone = location?.timezone || 'UTC';
    const isDifferentTimezone = userTimezone !== localTimezone;
    const titleText = isDifferentTimezone ? `Local Time (${localTimezone}): ${formatTimestamp(localTimezone)}` : '';

    return (
        <span title={titleText}>
            {formatTimestamp(userTimezone)}
        </span>
    );
};

export default TimeDisplay;