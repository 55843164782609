import React from "react";
import "./index.css";

export default function SlideToUnlock(props) {
    const InputRef = React.useRef(null);

    React.useEffect(() => {
        // 👇 Get the DOM element from the React element
        const slider = InputRef.current;

        if (!slider) return;

        let startValue = -1;

        function checkStart() {
            if (startValue === -1 && slider.value < 20) {
                startValue = slider.value;
            } else if (startValue === -1) {
                slider.value = 0;
            }
        }

        function unlockEndHandler() {
            if (startValue > 0 && slider.value === slider.max) {
                if (props.onUnlock) {
                    props.onUnlock();
                }
            }
            startValue = -1;
            slider.value = 0;
        }

        slider.addEventListener("change", unlockEndHandler);
        slider.addEventListener("input", checkStart);
        return () => {
            slider.removeEventListener("change", unlockEndHandler);
            slider.removeEventListener("input", checkStart);
        };
    }, []);

    return (
        <div className="range-container">
            <input ref={InputRef} type="range" min="0" max="100" defaultValue="0"></input>
            <div className="overlay-text">{props.display}</div>
        </div >
    );
}
