import React from "react";
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FaCode, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ModalImage from "react-modal-image";
import { Link, useParams, useHistory } from "react-router-dom";
import * as api from "../../api/api";
import * as firestoreApi from "../../api/firestoreApi.js";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import DevMenuButton from "../../components/DevMenuButton";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import { MeasurementTagger } from "../../components/ItemTagger";
import Layout, { ImageBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseFirestore, getFirebaseStorageDownloadUrl } from "../../corvusFirebase.js";
import TimeDisplay from '../../components/TimeDisplay/index.jsx';
import urls from "../../urls.js";
import "./index.css";

export default function ImagePage() {
    const [measurement, setMeasurement] = React.useState(null);
    const [nextMeasurement, setNextMeasurement] = React.useState(undefined);
    const [previousMeasurement, setPreviousMeasurement] = React.useState(undefined);
    const { organizationId, locationId, missionId, measurementId } = useParams();
    const [view, setView] = React.useState('data');
    const [imageUrl, setImageUrl] = React.useState(null);
    const { getUserIsAdmin } = useUser();
    const history = useHistory();

    const hasNextMeasurement = React.useCallback(() => {
        return nextMeasurement !== undefined;
    }, [nextMeasurement]);

    const hasPreviousMeasurement = React.useCallback(() => {
        return previousMeasurement !== undefined;
    }, [previousMeasurement]);

    const visitNextMeasurement = React.useCallback(() => {
        if (hasNextMeasurement()) {
            setImageUrl(null); // Ensure that the old image is gone
            history.push(urls.measurement(organizationId, locationId, missionId, nextMeasurement.id));
        }
    }, [hasNextMeasurement, setImageUrl, history, organizationId, locationId, missionId, nextMeasurement]);

    const visitPreviousMeasurement = React.useCallback(() => {
        if (hasPreviousMeasurement()) {
            setImageUrl(null); // Ensure that the old image is gone
            history.push(urls.measurement(organizationId, locationId, missionId, previousMeasurement.id));
        }
    }, [hasPreviousMeasurement, setImageUrl, history, organizationId, locationId, missionId, previousMeasurement]);

    React.useEffect(() => {
        const fetchPreviousAndNextMeasurement = async () => {
            if (measurement) {
                // Subscribe to previous measurement
                const previousMeasurementQuery = getFirebaseFirestore()
                    .collection(`organizations/${organizationId}/locations/${locationId}/measurements`)
                    .where('event.summary.MEASUREMENT_STARTED', '<', measurement.event.summary.MEASUREMENT_STARTED)
                    .where('meta.mission.id', '==', missionId)
                    .orderBy('event.summary.MEASUREMENT_STARTED', 'desc')
                    .limit(1);

                const previousMeasurementUnsubscrive = previousMeasurementQuery.onSnapshot((snapshot) => {
                    const newPreviousMeasurement = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                    }))[0];

                    setPreviousMeasurement(newPreviousMeasurement)
                });

                // Subscribe to next measurement
                const nextMeasurementQuery = getFirebaseFirestore()
                    .collection(`organizations/${organizationId}/locations/${locationId}/measurements`)
                    .where('event.summary.MEASUREMENT_STARTED', '>', measurement.event.summary.MEASUREMENT_STARTED)
                    .where('meta.mission.id', '==', missionId)
                    .orderBy('event.summary.MEASUREMENT_STARTED', 'asc')
                    .limit(1);

                const nextMeasurementUnsubscribe = nextMeasurementQuery.onSnapshot((snapshot) => {
                    const newNextMeasurement = snapshot.docs.map((doc) => ({
                        ...doc.data(),
                        id: doc.id,
                        organizationId: organizationId,
                        locationId: locationId,
                    }))[0];

                    setNextMeasurement(newNextMeasurement)
                });

                return () => {
                    // Unsubscribe when the component unmounts
                    previousMeasurementUnsubscrive();
                    nextMeasurementUnsubscribe();
                };
            }
        };

        fetchPreviousAndNextMeasurement();
    }, [organizationId, locationId, missionId, measurement]);

    React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                visitPreviousMeasurement();
            } else if (event.key === 'ArrowRight') {
                visitNextMeasurement();
            }
        };

        // Add event listeners when the component mounts
        window.addEventListener('keydown', handleKeyDown);

        // Remove event listeners when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [visitPreviousMeasurement, visitNextMeasurement]);

    React.useEffect(() => {
        return api.subscribeMeasurement(organizationId, locationId, measurementId, setMeasurement);
    }, [organizationId, locationId, missionId, measurementId]);

    React.useEffect(() => {
        if (measurement) {
            const bucketPath = `organizations/${organizationId}/locations/${locationId}/measurements/${measurement.id}.jpg`;
            return getFirebaseStorageDownloadUrl(bucketPath).then(setImageUrl);
        }
    }, [organizationId, locationId, measurement]);

    return (
        <Layout>
            <ImageBreadcrumbContainer />
            <Card className="mb-4">
                <Title title={
                    <>
                        <BackButton
                            href={urls.missionMeasurements(organizationId, locationId, missionId)}
                        />
                        Measurement {measurement && <TimeDisplay
                            timestamp={new Date(measurement.event.summary.MEASUREMENT_STARTED).getTime() / 1000}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        />}
                    </>
                }>
                    <div>
                        {getUserIsAdmin() && (
                            <DevMenuButton>
                                <Dropdown.Item onClick={() => setView(view === 'data' ? 'meta' : 'data')}>
                                    <FaCode
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    {view === 'data' && <>View Meta</>}
                                    {view === 'meta' && <>View Data</>}
                                </Dropdown.Item>
                                <FirestoreDevMenuItem
                                    as={Dropdown.Item}
                                    path={firestoreApi.getMeasurementDocumentPath(organizationId, locationId, measurementId)}
                                />
                                {measurement && (
                                    <>
                                        <Dropdown.Item
                                            as={Link}
                                            to={urls.flight(organizationId, locationId, measurement.meta.flight.id)}
                                        >
                                            Go to Flight
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            as={Link}
                                            to={urls.locationMission(organizationId, locationId, measurement.meta.mission.id)}
                                        >
                                            Go to Mission
                                        </Dropdown.Item>
                                    </>
                                )}
                            </DevMenuButton>
                        )}
                        <ButtonGroup>
                            <Button
                                variant="outline-secondary"
                                className='px-4'
                                onClick={visitPreviousMeasurement}
                                disabled={!hasPreviousMeasurement()}
                            >
                                <FaArrowLeft
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                            </Button>
                            <Button
                                variant="outline-secondary"
                                className='px-4'
                                onClick={visitNextMeasurement}
                                disabled={!hasNextMeasurement()}
                            >
                                <FaArrowRight
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                            </Button>
                        </ButtonGroup>
                    </div>
                </Title>
                {view === 'meta' && (
                    <Card.Body className='p-4 pt-0'>
                        <CodeBlock name='Measurement' code={measurement} />
                    </Card.Body>
                )}
                {view === 'data' && (
                    <>
                        {getUserIsAdmin() && (
                            <Card.Body className="p-4 pt-0">
                                <MeasurementTagger
                                    selected={measurement?.tags || []}
                                    onChange={(newTags) => api.updateMeasurementTags(organizationId, locationId, measurementId, newTags)}
                                />
                            </Card.Body>
                        )}
                        <Card.Body className='p-4 pt-0'>
                            <ModalImage
                                // hideZoom={true}
                                hideDownload={true}
                                small={imageUrl}
                                large={imageUrl}
                            />
                        </Card.Body>
                    </>
                )}
            </Card>
        </Layout >
    );
}
