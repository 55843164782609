import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import TimeDisplay from "../TimeDisplay";
import urls from "../../urls.js";
import moment from 'moment-timezone';

var cronstrue = require("cronstrue/i18n");

export default function RoutinePreview(props) {
    function describeCronJob(cronString) {
        return cronstrue.toString(cronString, {
            throwExceptionOnParseError: false,
            locale: "en",
            use24HourTimeFormat: true,
            verbose: true
        });
    }

    const userTimezone = moment.tz.guess();
    const localTimezone = props.location?.timezone;
    const showTimezone = localTimezone !== undefined && localTimezone !== userTimezone;

    return (
        <Alert
            variant="primary"
            className={props.className}
        >
            Start task
            {' '}
            <Link to={urls.task(
                props.organizationId,
                props.locationId,
                props.routineTask?.id)}
            >
                {props.routineTask?.name}
            </Link>
            {' '}
            {describeCronJob(props.routine?.interval).toLowerCase()}
            {showTimezone && " in timezone '" + localTimezone +"'"}
            {' '}on drone{' '}
            <Link to={urls.locationDrone(
                props.organizationId,
                props.locationId,
                props.routineDrone?.id)
            }>
                {props.routineDrone?.id}
            </Link>
            {
                props.routineMission &&
                <>
                    , continuing with mission <Link to={urls.locationMission(
                        props.organizationId,
                        props.locationId,
                        props.routineMission.id)
                    }>
                        {<TimeDisplay
                            timestamp={props.routineMission.timestamp.seconds}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={props.organizationId}
                            locationId={props.locationId}
                        />}
                    </Link>
                </>
            }
        </Alert>
    );
}
