// Here, this might be an improvement:
// https://xrutayisire.github.io/react-js-cron/?path=/docs/reactjs-cron--demo

import moment from 'moment-timezone';
import React from "react";
import { Accordion, Button, ButtonGroup, Card, Dropdown, DropdownButton, ListGroup, Modal } from "react-bootstrap";
import { FaCheck, FaCode, FaCog, FaDatabase, FaStopwatch, FaRegHourglass, FaTrash } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import CodeBlock from "../../components/CodeBlock";
import Layout, { RoutineBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import RoutinePreview from "../../components/RoutinePreview";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
const parser = require("cron-parser");

export default function RoutinePage() {
    const [routine, setRoutine] = React.useState();
    const [view, setView] = React.useState('default');
    const [show, setShow] = React.useState(false);
    const { organizationId, locationId, routineId } = useParams();
    const history = useHistory();
    const [routineTask, setRoutineTask] = React.useState();
    const [routineDrone, setRoutineDrone] = React.useState();
    const [routineMission, setRoutineMission] = React.useState();
    const [runtimes, setRuntimes] = React.useState([]);
    const [location, setLocation] = React.useState(null);
    const { getUserIsAdmin } = useUser();

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    function visitRoutines() {
        history.push(urls.routines(organizationId, locationId));
    }

    function onDeleteRoutine() {
        api.deleteRoutine(organizationId, locationId, routineId).then(visitRoutines);
    }

    function handleClose() {
        setShow(false);
    }

    function handleShow() {
        setShow(true);
    }

    React.useEffect(() => {
        if (organizationId && routineId) {
            api.subscribeRoutine(organizationId, locationId, routineId, setRoutine);
        }
    }, [organizationId, locationId, routineId]);

    React.useEffect(() => {
        if (routine?.droneId !== routineDrone?.id) {
            console.log(`Loading drone '${routine.droneId}' ...`);
            api.getDroneConfig(routine.droneId, setRoutineDrone);
        }
    }, [routine, routineDrone]);

    React.useEffect(() => {
        if (routine?.taskId !== routineTask?.id) {
            api.getTask(organizationId, locationId, routine.taskId, setRoutineTask);
        }
    }, [organizationId, locationId, routine, routineTask]);

    React.useEffect(() => {
        if (routine?.missionId?.length === 0 || routine?.missionId === undefined) {
            setRoutineMission(undefined);
        }
        else if (routine?.missionId !== routineMission?.id) {
            api.subscribeMission(organizationId, locationId, routine.missionId, setRoutineMission);
        }
    }, [organizationId, locationId, routine, routineMission]);

    React.useEffect(() => {
        if (routine) {
            try {
                const intervalIterator = parser.parseExpression(
                    routine.interval
                );
                setRuntimes(
                    [1, 2, 3].map((i) =>
                        intervalIterator.next().toISOString()
                    )
                );
            } catch {
                setRuntimes([]);
            }
        }
    }, [routine]);

    function visitFirestore() {
        const organizationPath = `organizations/${organizationId}`;
        const locationPath = `${organizationPath}/locations/${locationId}`;
        const routinePat = `${locationPath}/routines/${routineId}`;

        window.open(`https://console.firebase.google.com/u/0/project/corvus-backend/firestore/data/~2F${routinePat}`);
    }

    return (
        <Layout>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Routine</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure to permanently delete this routine?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={onDeleteRoutine}
                    >
                        <FaTrash
                            className="me-2"
                            style={{ marginTop: "-2px" }}
                        />
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <RoutineBreadcrumbContainer />
            {routine && (
                <Card className="m-0 mb-4">
                    <Title title={
                        <>
                            <BackButton href={urls.routines(organizationId, locationId)} />
                            {routine.name || 'Routine'}
                        </>
                    }>

                        <ButtonGroup>
                            <Button
                                variant="secondary"
                                as={Link}
                                to={urls.routineSettings(organizationId, locationId, routineId)}
                            >
                                <FaCog
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Settings
                            </Button>
                            <DropdownButton
                                variant="secondary"
                                align="end"
                                as={ButtonGroup}
                            >
                                {getUserIsAdmin() &&
                                    <Dropdown.Item
                                        as={Link}
                                        to={`${urls.demo()}?organizationId=${organizationId}&locationId=${locationId}&routineId=${routineId}`}
                                    >
                                        <FaStopwatch
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Demo page
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item onClick={visitFirestore}>
                                    <FaDatabase
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    View Firestore
                                </Dropdown.Item>
                                {view === 'data' && (
                                    <Dropdown.Item
                                        onClick={() => setView('default')}
                                    >
                                        <FaCode
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Show Data
                                    </Dropdown.Item>
                                )}
                                {view === 'default' && (
                                    <Dropdown.Item
                                        onClick={() => setView('data')}
                                    >
                                        <FaCode
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Show Default
                                    </Dropdown.Item>
                                )}
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    className="text-danger"
                                    onClick={handleShow}
                                >
                                    <FaTrash
                                        className="me-2"
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                    />
                                    Delete
                                </Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    </Title>
                    {view === 'default' && (
                        <Card.Body className="p-4 pt-0">
                            <RoutinePreview
                                className='m-0'
                                organizationId={organizationId}
                                locationId={locationId}
                                location={location}
                                routineTask={routineTask}
                                routineDrone={routineDrone}
                                routineMission={routineMission}
                                runtimes={runtimes}
                                routine={routine}
                            />
                        </Card.Body>
                    )}
                    {view === 'data' && (
                        <Card.Body className="p-4 pt-0">
                            <CodeBlock name='Routine' code={routine} />
                        </Card.Body>
                    )}
                </Card>
            )}
            <Accordion className="mb-4">
                <Accordion.Item eventKey={'upcomming'}>
                    <Accordion.Header>
                        <FaRegHourglass
                            className="me-4 text-primary"
                            style={{ marginTop: "-2px" }}
                        />
                        Upcomming executions
                    </Accordion.Header>
                    <Accordion.Body className="p-0">
                        <ListGroup variant='flush'>
                            {runtimes.map((runtime, index) => {
                                const amsterdamTime = moment.tz(runtime, 'Europe/Amsterdam');
                                const formattedTime = amsterdamTime.format('YYYY-MM-DD HH:mm:ss');
                                return (
                                    <ListGroup.Item>
                                        Run{' '}{index + 1}{' '}will be at{' '}
                                        <code>{formattedTime}</code>
                                    </ListGroup.Item>
                                );
                            })}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={'past'}>
                    <Accordion.Header>
                        <FaCheck
                            className="me-4 text-primary"
                            style={{ marginTop: "-2px" }}
                        />
                        Past executions
                    </Accordion.Header>
                    <Accordion.Body className="p-4">
                        TODO
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Layout>
    );
}
