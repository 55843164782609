// TODO : display errors on this page

import React from "react";
import { Button, Card } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, { OrganizationBreadcrumbContainer, Title } from "../../components/Layout/Layout";

import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";


export default function LocationListPage() {
    const { organizationId } = useParams();
    const { getUserIsAdmin } = useUser();
    const [locations, setLocations] = React.useState([]);
    const history = useHistory();
    const { userClaims } = useUser();

    if (!organizationId) {
        history.push(urls.organization(userClaims.organizationId));
    }

    React.useEffect(() => {
        return api.subscribeLocations(organizationId, setLocations);
    }, [organizationId]);

    return (
        <Layout>
            {getUserIsAdmin() && <OrganizationBreadcrumbContainer />}
            <Card>
                <Title title={
                    <>
                        {getUserIsAdmin() &&
                            <BackButton href={urls.organizations()} />
                        }
                        Locations
                    </>
                }>
                    {getUserIsAdmin() && (
                        <Button
                            variant="outline-success"
                            as={Link}
                            to={urls.locationCreate(organizationId)}
                        >
                            <FaPlus className="me-2" />
                            New Location
                        </Button>
                    )}
                </Title>
                <CorvusList>
                    {locations &&
                        locations
                        .filter(location => !location.deleted)
                        .map((location) => (
                            <CorvusListItem
                                className="p-0"
                                key={location.id}
                                as={Link}
                                to={urls.location(organizationId, location.id)}
                            >
                                <CorvusListItemText>
                                    {location.name}
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                </CorvusList>
            </Card>
        </Layout>
    );
}
