import axios from "axios";
import { arrayUnion } from "firebase/firestore";
import { getFirebaseAuth, getFirebaseFirestore, getFirebaseFunction, getFirebaseStorageDownloadUrl } from "../corvusFirebase.js";
import * as firestoreApi from "./firestoreApi.js";
import { serverTimestamp } from "firebase/firestore";
import { STATE_PENDING } from "./missionApi";

const papa = require("papaparse");
const short = require("short-uuid");

export function createTask(organizationId, locationId, task) {
    return new firestoreApi.TaskCollection(organizationId, locationId).add({ ...task, deleted: false });
}

export function createDock(organizationId, locationId, dock) {
    return new firestoreApi.DockCollection(organizationId, locationId).add(dock);
}

export function createLocation(organizationId, location) {
    return new firestoreApi.LocationCollection(organizationId).add(location);
}

export function saveTask(organizationId, locationId, taskId, task) {
    return new firestoreApi.TaskDocument(organizationId, locationId, taskId).set(task);
}

export function saveDock(organizationId, locationId, dockId, dock) {
    return new firestoreApi.DockDocument(organizationId, locationId, dockId).set(dock);
}

export function createRoutine(organizationId, locationId, routine) {
    return new firestoreApi.RoutineCollection(organizationId, locationId).add(routine);
}

export function addAppToMission(organizationId, locationId, missionId, appId) {
    console.log(organizationId, locationId, missionId, appId);
    return new firestoreApi.MissionDocument(organizationId, locationId, missionId).update({
        apps: arrayUnion({ id: appId }),
    });
}

export function sendFeedback(collectionPath, feedback) {
    console.log(`(Firestore) Sending feedback ...`);
    return getFirebaseFirestore()
        .collection(collectionPath)
        .add(feedback);
}

export function getOrganizations(setOrganizations) {
    const organizationCollection = new firestoreApi.OrganizationCollection();

    const organizationQuery = organizationCollection.createQuery()
        .orderBy('name', 'asc');

    return organizationCollection.subscribe(setOrganizations, organizationQuery);
}

export function getLocations(organizationId, setLocations) {
    const locationCollection = new firestoreApi.LocationCollection(organizationId);

    const locationQuery = locationCollection.createQuery()
        .orderBy('name', 'asc');

    return locationCollection.subscribe(setLocations, locationQuery);
}

export function getPayloads(setPayloads) {
    const payloadCollection = new firestoreApi.PayloadCollection();

    const payloadQuery = payloadCollection.createQuery()
        .orderBy('name', 'asc');

    return payloadCollection.subscribe(setPayloads, payloadQuery);
}

export function subscribeAreas(organizationId, locationId, callback, keepmeta = false) {
    console.log(`(Firestore) Subscribing to areas ...`);
    const documentPath = firestoreApi.getAreaDocumentPath(organizationId, locationId);

    const unsubscribe = getFirebaseFirestore()
        .doc(documentPath)
        .onSnapshot((snapshot) => {
            console.log("(Firestore) Received Areas");
            if (keepmeta) {
                callback(snapshot.exists ? snapshot.data() || {} : {});
            } else {
                callback(snapshot.exists ? snapshot.data().areas || {} : {});
            }
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from areas ...`);
        unsubscribe();
    };
}

export function getAreas(organizationId, locationId, setAreas, keepmeta = false) {
    console.log(`(Firestore) Getting areas ...`);
    const documentPath = firestoreApi.getAreaDocumentPath(organizationId, locationId);

    return getFirebaseFirestore()
        .doc(documentPath)
        .get()
        .then((snapshot) => {
            let areas;
            if (keepmeta) {
                areas = snapshot.exists ? snapshot.data() || {} : {};
            } else {
                areas = snapshot.exists ? snapshot.data().areas || {} : {};
            }

            if (setAreas) setAreas(areas);
            return areas;
        });
}

export function subscribeFlyzones(organizationId, locationId, setFlyzones, keepmeta = false) {
    console.log(`(Firestore) Subscribing to flyzones ...`);
    const documentPath = firestoreApi.getFlyzoneDocumentPath(organizationId, locationId);

    const unsubscribe = getFirebaseFirestore()
        .doc(documentPath)
        .onSnapshot((snapshot) => {
            console.log("(Firestore) Received Flyzones");
            if (keepmeta) {
                setFlyzones(snapshot.exists ? snapshot.data() || {} : {});
            } else {
                setFlyzones(snapshot.exists ? snapshot.data().flyzones || {} : {});
            }
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from flyzones ...`);
        unsubscribe();
    };
}

export function getFlyzones(organizationId, locationId, setFlyzones, keepmeta = false) {
    console.log(`(Firestore) Getting flyzones ...`);
    const documentPath = firestoreApi.getFlyzoneDocumentPath(organizationId, locationId);

    return getFirebaseFirestore()
        .doc(documentPath)
        .get()
        .then((snapshot) => {
            let flyzones;
            if (keepmeta) {
                flyzones = snapshot.exists
                    ? snapshot.data() || []
                    : [];
            } else {
                flyzones = snapshot.exists
                    ? snapshot.data().flyzones || []
                    : [];
            }
            if (setFlyzones) setFlyzones(flyzones);
            return flyzones;
        });
}

export function subscribeMarkers(organizationId, locationId, setMarkers) {
    console.log(`(Firestore) Subscribing to markers ...`);
    const documentPath = firestoreApi.getMarkerDocumentPath(organizationId, locationId);

    const unsubscribe = getFirebaseFirestore()
        .doc(documentPath)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Received Markers`);
            setMarkers(snapshot.exists ? snapshot.data() || {} : {});
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from markers ...`);
        unsubscribe();
    };
}

export function getMarkers(organizationId, locationId, setMarkers) {
    console.log(`(Firestore) Getting markers ...`);
    const documentPath = firestoreApi.getMarkerDocumentPath(organizationId, locationId);

    return getFirebaseFirestore()
        .doc(documentPath)
        .get()
        .then((snapshot) => {
            const markers = snapshot.exists
                ? snapshot.data().markers || []
                : [];
            if (setMarkers) setMarkers(markers);
            return markers;
        });
}

export function subscribeZones(organizationId, locationId, setZones) {
    console.log(`(Firestore) Subscribing to zones ...`);
    const documentPath = firestoreApi.getZoneDocumentPath(organizationId, locationId);

    const unsubscribe = getFirebaseFirestore()
        .doc(documentPath)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Received Zones`);
            setZones(snapshot.exists ? snapshot.data().zones || [] : []);
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from zones ...`);
        unsubscribe();
    };
}

export function getZones(organizationId, locationId, setZones) {
    console.log(`(Firestore) Getting zones ...`);
    const documentPath = firestoreApi.getZoneDocumentPath(organizationId, locationId);
    console.log(`(Firestore) Zones document path: ${documentPath}`);

    return getFirebaseFirestore()
        .doc(documentPath)
        .get()
        .then((snapshot) => {
            // TODO : this callback function everywhere, including the new api functions/classes
            let zones = [];
            if (snapshot.exists) zones = snapshot.data().zones || [];
            if (setZones) setZones(zones);
            return zones;
        });
}

export function getLocation(organizationId, locationId, setLocation) {
    return new firestoreApi.LocationDocument(organizationId, locationId).get(setLocation);
}

export function subscribeLocation(organizationId, locationId, setLocation) {
    return new firestoreApi.LocationDocument(organizationId, locationId).subscribe(setLocation);
}

export function setLocation(organizationId, locationId, newLocation) {
    // TODO : rename to saveLocation
    return new firestoreApi.LocationDocument(organizationId, locationId).set(newLocation);
}

export function subscribeLocations(organizationId, setLocations) {
    const locationCollection = new firestoreApi.LocationCollection(organizationId);

    const locationQuery = locationCollection.createQuery()
        .orderBy('name', 'asc');

    return locationCollection.subscribe(setLocations, locationQuery);
}

export function setAreas(organizationId, locationId, newAreas) {
    console.log(`(Firestore) Setting areas ...`);
    newAreas = newAreas.areas ? newAreas : { areas: newAreas };

    // TODO : Drone can not deserialize this yet, but we need it in the UI
    newAreas.updatedAt = serverTimestamp();

    // TODO : remove this when fully migrated
    const oldAreaDocumentPath = `organizations/${organizationId}/locationAreas/${locationId}`;
    const writeOldLocation = getFirebaseFirestore()
        .doc(oldAreaDocumentPath)
        .set(newAreas);

    const newDocumentPath = firestoreApi.getAreaDocumentPath(organizationId, locationId);
    console.log(newAreas);
    console.log(firestoreApi.getAreaDocumentPath(organizationId, locationId));
    const writeNewLocation = getFirebaseFirestore()
        .doc(newDocumentPath)
        .set(newAreas);

    return Promise.all([writeOldLocation, writeNewLocation]);
}

export function executePullSystemsCommand(droneId) {
    const command = { type: "PULL_SYSTEMS" };
    executeCommands(droneId, [command]);
}

export function executeRestartSystemCommand(droneId) {
    const command = { type: "RESTART_SYSTEM" };
    executeCommands(droneId, [command]);
}

export function downloadSystem(droneId, newSystem) {
    // TODO : Move this to the ClientApi

    console.log(`(Firestore) Downloading local system '${newSystem}' ...`);
    return getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .set({ systems: { [newSystem]: true } }, { merge: true })
        .then(() => {
            return executePullSystemsCommand(droneId);
        });
}

export function deleteSystem(droneId, system) {
    // TODO : Move this to the ClientApi

    console.log(`(Firestore) Deleting local system '${system}' ...`);

    return getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .set({ systems: { [system]: false } }, { merge: true })
        .then(() => {
            return executePullSystemsCommand(droneId);
        });
}

export function runSystem(droneId, system) {
    // TODO : Move this to the ClientApi

    console.log(`(Firestore) Running system '${system}' ...`);
    return getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .set({ system }, { merge: true })
        .then(() => {
            return executeRestartSystemCommand(droneId);
        });
}

export function setFlyzones(organizationId, locationId, newFlyzones) {
    console.log(`(Firestore) Setting flyzones ...`);
    newFlyzones = newFlyzones.flyzones ? newFlyzones : { flyzones: newFlyzones };

    // if a flyzone has no z values, use default values
    for (let i = 0; i < newFlyzones.flyzones.length; i++) {
        newFlyzones.flyzones[i].z_min = newFlyzones.flyzones[i].z_min ? newFlyzones.flyzones[i].z_min : "0";
        newFlyzones.flyzones[i].z_max = newFlyzones.flyzones[i].z_max ? newFlyzones.flyzones[i].z_max : "4";
    }

    newFlyzones.updatedAt = serverTimestamp();

    // TODO : remove this when fully migrated
    const writeOldLocation = getFirebaseFirestore()
        .doc(`organizations/${organizationId}/locationFlyzones/${locationId}`)
        .set(newFlyzones);

    const newDocumentPath = firestoreApi.getFlyzoneDocumentPath(organizationId, locationId);
    const writeNewLocation = getFirebaseFirestore()
        .doc(newDocumentPath)
        .set(newFlyzones);

    return Promise.all([writeOldLocation, writeNewLocation]);
}

export function setMarkers(organizationId, locationId, newMarkers) {
    console.log(`(Firestore) Setting markers ...`);
    newMarkers = newMarkers.markers ? newMarkers : { markers: newMarkers };

    // TODO : Drone can not deserialize this yet, but we need it in the UI
    newMarkers.updatedAt = serverTimestamp();

    // TODO : remove this when fully migrated
    const writeOldLocation = getFirebaseFirestore()
        .doc(`organizations/${organizationId}/locationMarkers/${locationId}`)
        .set(newMarkers);

    const newDocumentPath = firestoreApi.getMarkerDocumentPath(organizationId, locationId);
    const writeNewLocation = getFirebaseFirestore()
        .doc(newDocumentPath)
        .set(newMarkers);

    return Promise.all([writeOldLocation, writeNewLocation]);
}

export function getOrganization(organizationId, setOrganization) {
    return new firestoreApi.OrganizationDocument(organizationId).get(setOrganization);
}

export function getCommands(setCommands) {
    console.log(`(Firestore) Getting commands ...`);
    // TODO : is this the best structure in firestore?
    // or should we make a collection for command types?

    return getFirebaseFirestore()
        .doc(`corvus/commands`)
        .get()
        .then((snapshot) => setCommands(snapshot.data().commands));
}

export function getNotifications(setNotifications) {
    console.log(`(Firestore) Getting notifications ...`);
    // TODO : is this the best structure in firestore?
    // or should we make a collection for command types?

    return getFirebaseFirestore()
        .doc(`corvus/notifications`)
        .get()
        .then((snapshot) => setNotifications(snapshot.data().notifications));
}

export function updateDroneConfig(droneId, droneConfig) {
    console.log(`(Firestore) Updating droneConfig of ${droneId} ...`);

    return getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .set(droneConfig, { merge: true });
}

export function updatePayloadConfig(payloadId, payloadConfig) {
    console.log(`(Firestore) Updating payloadConfig of ${payloadId} ...`);

    return getFirebaseFirestore()
        .doc(`payloads/${payloadId}`)
        .set(payloadConfig, { merge: true });
}

export function saveMission(organizationId, locationId, missionId, mission) {
    // Create a new mission object without organizationId and locationId
    const sanitizedMission = {
        ...mission,
        organizationId: undefined,
        locationId: undefined
    };

    // Create a new MissionDocument instance and set the sanitized mission
    return new firestoreApi.MissionDocument(organizationId, locationId, missionId).set(sanitizedMission);
}

export function deleteMission(organizationId, locationId, missionId) {
    return new firestoreApi.MissionDocument(organizationId, locationId, missionId)
        .update({ deleted: true });
}

export function deleteLocation(organizationId, locationId) {
    return new firestoreApi.LocationDocument(organizationId, locationId)
        .update({ deleted: true });
}

export function restoreLocation(organizationId, locationId) {
    return new firestoreApi.LocationDocument(organizationId, locationId)
        .update({ deleted: false });
}

export function saveLocation(organizationId, locationId, location) {
    return new firestoreApi.LocationDocument(organizationId, locationId).set(location);
}

export function closeErrors(droneId) {
    console.log(`(Firestore) Closing errors for '${droneId}' ...`);

    return getFirebaseFirestore()
        .doc(`droneFeedbacks/${droneId}`)
        .set({ errors: {} }, { merge: true });
}

export function deleteTask(organizationId, locationId, taskId) {
    return new firestoreApi.TaskDocument(organizationId, locationId, taskId)
        .update({ deleted: true });
}

export function deleteDock(organizationId, locationId, dockId) {
    return new firestoreApi.DockDocument(organizationId, locationId, dockId)
        .delete();
}

export function deleteRoutine(organizationId, locationId, routineId) {
    return new firestoreApi.RoutineDocument(organizationId, locationId, routineId).delete();
}

export function saveRoutine(organizationId, locationId, routineId, routine) {
    return new firestoreApi.RoutineDocument(organizationId, locationId, routineId).set(routine);
}

export function updateRoutine(organizationId, locationId, routineId, routineFieldsToUpdate) {
    return new firestoreApi.RoutineDocument(organizationId, locationId, routineId).set(routineFieldsToUpdate);
}

export function getRoutine(organizationId, locationId, routineId, setRoutine) {
    return new firestoreApi.RoutineDocument(organizationId, locationId, routineId).get(setRoutine);
}

export async function deleteAlerts(droneId, alertLevel) {
    const alertCollection = new firestoreApi.AlertCollection();

    const alertQuery = alertCollection.createQuery()
        .where('level', '==', alertLevel);

    const querySnapshot = await alertQuery.get();
    querySnapshot.forEach((doc) => {
        console.log(`(Firestore) Deleting alert document '${doc.id}' ...`);
    });

    const droneAlertDocumentPaths = querySnapshot.docs.map((doc) => {
        return firestoreApi.getNewDroneAlertDocumentPath(droneId, doc.id);
    });

    const batch = getFirebaseFirestore().batch();
    droneAlertDocumentPaths.forEach((documentPath) => {
        console.log(`(Firestore) Deleting drone alert document '${documentPath}' ...`);
        batch.delete(getFirebaseFirestore().doc(documentPath));
    });

    return batch.commit();
}

export function getTasks(organizationId, locationId, setTasks) {
    const taskCollection = new firestoreApi.TaskCollection(organizationId, locationId);

    const taskQuery = taskCollection.createQuery()
        .where('deleted', '==', false)
        .orderBy('name', 'asc');

    return taskCollection.subscribe(setTasks, taskQuery);
}

export function getDocks(organizationId, locationId, setDocks) {
    return new firestoreApi.DockCollection(organizationId, locationId).get(setDocks);
}

export function getCorvusApp(appId, setApp) {
    return new firestoreApi.AppDocument(appId).get(setApp);
}

export function getCorvusApps(setApps) {
    const appCollection = new firestoreApi.AppCollection();

    const appQuery = appCollection.createQuery()
        .orderBy('name', 'asc');

    return appCollection.subscribe(setApps, appQuery);
}

export function subscribeTask(organizationId, locationId, taskId, setTask) {
    return new firestoreApi.TaskDocument(organizationId, locationId, taskId).subscribe(setTask);
}

export async function getLocationApps(organizationId, locationId, setLocationApps) {
    const location = await new firestoreApi.LocationDocument(organizationId, locationId).get();
    const corvusApps = await new firestoreApi.AppCollection().get();
    const relevantAppIds = (location.apps || []).map((a) => a.id);
    const locationApps = corvusApps.filter((a) => relevantAppIds.includes(a.id));
    setLocationApps(locationApps);
    return locationApps;
}

export function getTask(organizationId, locationId, taskId, setTask) {
    return new firestoreApi.TaskDocument(organizationId, locationId, taskId).get(setTask);
}

export function getDock(organizationId, locationId, dockId, setDock) {
    return new firestoreApi.DockDocument(organizationId, locationId, dockId).get(setDock);
}

export function getSystems(setSystems) {
    console.log(`(Firestore) Getting systems ...`);

    return getFirebaseFirestore().doc(`corvus/systems`).get()
        .then((snapshot) => {
            setSystems(snapshot.data());
        });
}

export function getDroneConfigs(organizationId, locationId, setDroneConfigs) {
    console.log(`(Firestore) Getting droneConfigs from organization ${organizationId}, location ${locationId} ...`);

    return getFirebaseFirestore()
        .collection(`droneConfigs`)
        .where("organizationId", "==", organizationId)
        .where("locationId", "==", locationId)
        // TODO : order by id
        .get()
        .then((snapshots) => {
            setDroneConfigs(snapshots.docs.map((snapshot) => snapshot.data()));
        });
}

export function getAllDroneConfigs(setDroneConfigs) {
    console.log(`(Firestore) Getting droneConfigs ...`);

    return getFirebaseFirestore()
        .collection(`droneConfigs`)
        .get()
        .then((snapshots) => {
            setDroneConfigs(snapshots.docs.map((snapshot) => snapshot.data()));
        });
}

export function getAllDroneIds(setDroneIds) {
    console.log(`(Firestore) Getting droneIds ...`);

    return getFirebaseFirestore()
        .collection(`droneConfigs`)
        .get()
        .then((snapshots) => {
            const ids = snapshots.docs.map((snapshot) => snapshot.id)
                .filter((id) => id !== 'presets');
            setDroneIds(ids);
        });
}

export function getDroneIds(organizationId, locationId, setDroneIds) {
    console.log(`(Firestore) Getting droneIds from organization ${organizationId}, location ${locationId}...`);

    return getFirebaseFirestore()
        .collection(`droneConfigs`)
        .where("organizationId", "==", organizationId)
        .where("locationId", "==", locationId)
        .get()
        .then((snapshots) => {
            const ids = snapshots.docs.map((snapshot) => snapshot.id)
                .filter((id) => id !== 'presets');
            setDroneIds(ids);
        });
}

export function subscribeDroneConfigs(organizationId, locationId, setDrones) {
    console.log(`(Firestore) subcribing to droneConfigs ...`);

    const unsubscribe = getFirebaseFirestore()
        .collection(`droneConfigs`)
        .where("organizationId", "==", organizationId)
        .where("locationId", "==", locationId)
        .onSnapshot((snapshots) => {
            setDrones(snapshots.docs.map((snapshot) => snapshot.data()));
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from droneConfigs ...`);
        unsubscribe();
    };
}

export function subscribeDronesFeedback(droneConfigs, setUnsubscribe, setDroneFeedback) {
    console.log(`(Firestore) subcribing to droneFeedbacks ...`);

    setDroneFeedback({});

    if (droneConfigs.length > 0) {
        console.log(`(Firestore) subcribing to ${droneConfigs.length} droneFeedbacks ... ${droneConfigs.map((config) => config.id)}`);

        const newUnsubscribe = droneConfigs.map((config) =>
            getFirebaseFirestore()
                .doc(`droneFeedbacks/${config.id}`)
                .onSnapshot((snapshot) => {
                    console.log(`(Firestore) Receiving droneFeedback ${config.id} ...`);
                    setDroneFeedback((previousState) => {
                        const feedback = { ...previousState };
                        if (snapshot.data() != null && snapshot.data().lastUpdate != null && (new Date() - snapshot.data().lastUpdate.toDate()) < 200000) {
                            feedback[config.id] = snapshot.data();
                        }
                        return feedback
                    });
                })
        );

        setUnsubscribe((previousState, newUnsubscribe) => {
            if (previousState) {
                previousState.map((un) => un()); // Unsubscribe from the previous dronefeedbacks
            }
            return newUnsubscribe;
        });

        return () => {
            if (newUnsubscribe) {
                newUnsubscribe.map((un) => un());
            }
        };
    }
    return () => { };
}

export function subscribeMission(organizationId, locationId, missionId, setMission) {
    const missionDocument = new firestoreApi.MissionDocument(organizationId, locationId, missionId);

    // Wrapper function to modify setMission callback
    const wrappedSetMission = (data) => {
        // Add organizationId and locationId to the mission
        const updatedData = { ...data, organizationId, locationId };
        // Call the original setMission callback
        setMission(updatedData);
    };

    // Call the original subscribe function with the wrappedSetMission callback
    return missionDocument.subscribe(wrappedSetMission);
}

export function subscribeMission2(missionId, setMission) {
    // TODO : remove this function, use subscribeMission instead
    // function is used for now to allow for mission browsing by missionId
    const path = 'missions';
    console.log(`(Firestore) Subscribing collectionGroup ${path} ...`);

    const unsubscribe = getFirebaseFirestore()
        .collectionGroup(path)
        .where('id', '==', missionId)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving ${path} snapshot ...`);
            if (snapshot.empty) return;
            const mission = {
                ...snapshot.docs[0].data(),
                id: snapshot.docs[0].id,
                organizationId: snapshot.docs[0].ref.parent.parent.parent.parent.id,
                locationId: snapshot.docs[0].ref.parent.parent.id,
            };
            if (setMission) setMission(mission);
            return mission;
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from ${path} ...`);
        unsubscribe();
    };
}

export function subscribeFlight2(flightId, setFlight) {
    // TODO : remove this function, use subscribeMission instead
    // function is used for now to allow for mission browsing by flightId
    const path = 'flights';
    console.log(`(Firestore) Subscribing collectionGroup ${path} ...`);

    const unsubscribe = getFirebaseFirestore()
        .collectionGroup(path)
        .where('id', '==', flightId)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving ${path} snapshot ...`);
            if (snapshot.empty) return;
            const mission = {
                ...snapshot.docs[0].data(),
                id: snapshot.docs[0].id,
                organization: { id: snapshot.docs[0].ref.parent.parent.parent.parent.id },
                location: { id: snapshot.docs[0].ref.parent.parent.id },
            };
            if (setFlight) setFlight(mission);
            return mission;
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from ${path} ...`);
        unsubscribe();
    };
}

export function subscribeFlight(organizationId, locationId, flightId, setFlight) {
    return new firestoreApi.FlightDocument(organizationId, locationId, flightId).subscribe(setFlight);
}

export function getFlight(organizationId, locationId, flightId, onData) {
    new firestoreApi.FlightDocument(organizationId, locationId, flightId).get(onData);
}

export function subscribeFlights(organizationId, locationId, flightIds, setUnsubscribe, setFlights) {
    console.log(`(Firestore) subcribing to droneFeedbacks ...`);

    setFlights({});

    if (flightIds.length > 0) {
        console.log(`(Firestore) subcribing to ${flightIds.length} flights ... ${flightIds}`);

        const newUnsubscribe = flightIds.map((id) =>
            getFirebaseFirestore()
                .doc(`organization/${organizationId}/locations/${locationId}/flights/${id}`)
                .onSnapshot((snapshot) => {
                    console.log(`(Firestore) Receiving flight ${id} ...`);
                    setFlights((previousState) => {
                        const flights = { ...previousState };
                        if (snapshot.data() != null && snapshot.data().lastUpdate != null) {
                            flights[id] = snapshot.data();
                        }
                        return flights
                    });
                })
        );

        setUnsubscribe((previousState, newUnsubscribe) => {
            if (previousState) {
                previousState.map((un) => un()); // Unsubscribe from the previous dronefeedbacks
            }
            return newUnsubscribe;
        });

        return () => {
            if (newUnsubscribe) {
                newUnsubscribe.map((un) => un());
            }
        };
    }
    return () => { };
}

export function subscribeMeasurement(organizationId, locationId, measurementId, setMeasurement) {
    return new firestoreApi.MeasurementDocument(organizationId, locationId, measurementId).subscribe(setMeasurement);
}

export function updateMeasurementTags(organizationId, locationId, measurementId, tags) {
    return new firestoreApi.MeasurementDocument(organizationId, locationId, measurementId).update({ tags });
}

export function updateFlightTags(organizationId, locationId, flightId, tags) {
    return new firestoreApi.FlightDocument(organizationId, locationId, flightId).update({ tags });
}

export function subscribeRoutine(organizationId, locationId, routineId, setRoutine) {
    return new firestoreApi.RoutineDocument(organizationId, locationId, routineId).subscribe(setRoutine);
}

export function getFeedbackOptions(feedbackForWhat, setFeedbackOptions) {
    console.log(`(Firestore) Getting feedback options ...`);

    return getFirebaseFirestore()
        .doc(`corvus/feedback`)
        .get()
        .then((snapshot) => {
            const feedback = snapshot.data();
            setFeedbackOptions(feedback[feedbackForWhat]);
            return feedback[feedbackForWhat];
        });
}

export function getCorvusTags(setCorvusTags) {
    console.log(`(Firestore) Getting Corvus tags ...`);

    return getFirebaseFirestore()
        .doc(`corvus/tags`)
        .get()
        .then((snapshot) => {
            const tags = snapshot.data();
            setCorvusTags(tags);
            return tags;
        });
}

export function subscribeAdminRoutines(setRoutines) {
    console.log(`(Firestore) Subscribing to routines ...`);

    const unsubscribe = getFirebaseFirestore()
        .collectionGroup(firestoreApi.ROUTINE_COLLECTION)
        .onSnapshot((snapshots) => {
            console.log(`(Firestore) Receiving ${snapshots.docs.length} routines ...`);
            setRoutines(snapshots.docs.map((snapshot) => {
                return {
                    ...snapshot.data(),
                    organizationId: snapshot.ref.parent.parent.parent.parent.id,
                    locationId: snapshot.ref.parent.parent.id,
                }
            }));
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from routines ...`);
        unsubscribe();
    };
}

export function subscribeAdminMissions(setMissions, limit = 100) {
    console.log(`(Firestore) Subscribing to ${limit} missions ...`);

    const unsubscribe = getFirebaseFirestore()
        .collectionGroup(firestoreApi.MISSION_COLLECTION)
        .where('deleted', '==', false)
        .orderBy('name', 'desc')
        .limit(limit)
        .onSnapshot((snapshots) => {
            console.log(`(Firestore) Receiving ${snapshots.docs.length} missions ...`);
            setMissions(snapshots.docs.map((snapshot) => {
                return {
                    ...snapshot.data(),
                    organizationId: snapshot.ref.parent.parent.parent.parent.id,
                    locationId: snapshot.ref.parent.parent.id,
                }
            }));
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from missions ...`);
        unsubscribe();
    };
}

export function subscribeAdminMeasurements(setMeasurements) {
    console.log(`(Firestore) Subscribing to measurements ...`);

    const unsubscribe = getFirebaseFirestore()
        .collectionGroup(firestoreApi.MEASUREMENT_COLLECTION)
        // .where('deleted', '==', false)
        .orderBy('name', 'desc')
        .limit(100)
        .onSnapshot((snapshots) => {
            console.log(`(Firestore) Receiving ${snapshots.docs.length} measurements ...`);
            setMeasurements(snapshots.docs.map((snapshot) => {
                return {
                    ...snapshot.data(),
                    _id: snapshot.id,
                    _organization: { id: snapshot.ref.parent.parent.parent.parent.id },
                    _location: { id: snapshot.ref.parent.parent.id },
                    // TODO : remove the following lines
                    id: snapshot.id,
                    organizationId: snapshot.ref.parent.parent.parent.parent.id,
                    locationId: snapshot.ref.parent.parent.id,
                }
            }));
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from measurements ...`);
        unsubscribe();
    };
}

export async function checkMissionIdExists(organizationId, locationId, missionId) {
    try {
        const missionDoc = new firestoreApi.MissionDocument(organizationId, locationId, missionId);
        const missionDocExists = await missionDoc.exists();
        if (missionDocExists) {
            const doc = await missionDoc.get();
            return !(doc.deleted === true); // Also return as existing if deleted field does not exist
        }
    } catch (error) {
        console.error("Error checking mission ID exists: ", error);
    }
    return false;
};

export function subscribeMissions(organizationId, locationId, setMissions, limit = 50) {
    const missionCollection = new firestoreApi.MissionCollection(organizationId, locationId);

    const missionQuery = missionCollection.createQuery()
        .where('deleted', '==', false)
        .orderBy('name', 'desc')
        .limit(limit);

    const setMissionsWithOrganizationLocationId = (missions) => {
        const updatedMissions = missions.map((mission) => ({
            ...mission,
            organizationId,
            locationId,
        }));
        setMissions(updatedMissions);
    };

    return missionCollection.subscribe(setMissionsWithOrganizationLocationId, missionQuery);
}

export function subscribeMissionFeedbacks(organizationId, locationId, missionId, setMissionFeedbacks) {
    return new firestoreApi.MissionFeedbackCollection(organizationId, locationId, missionId).subscribe(setMissionFeedbacks);
}

export function subscribeMeasurementFeedbacks(organizationId, locationId, measurementId, setMeasurementFeedbacks) {
    return new firestoreApi.MeasurementFeedbackCollection(organizationId, locationId, measurementId).subscribe(setMeasurementFeedbacks);
}

export function subscribeDroneMissions(organizationId, locationId, droneId, setMissions, limit) {
    const missionCollection = new firestoreApi.MissionCollection(organizationId, locationId);

    const missionQuery = missionCollection.createQuery()
        .where('deleted', '==', false)
        .where('drone.id', '==', droneId)
        .orderBy('name', 'desc')
        .limit(limit);

    const setMissionsWithOrganizationLocationId = (missions) => {
        const updatedMissions = missions.map((mission) => ({
            ...mission,
            organizationId,
            locationId,
        }));
        console.log("setting missions", updatedMissions);
        setMissions(updatedMissions);
    };

    return missionCollection.subscribe(setMissionsWithOrganizationLocationId, missionQuery);
}

export function sendStartUpload(droneId) {
    console.log(`(Firestore) Sending 'START_UPLOAD' command ...`);
    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "START_UPLOAD" }] },
            { merge: true });

    return excecuteCommand;
}

export function sendShutdown(droneId) {
    console.log(`(Firestore) Sending 'SHUTDOWN' command ...`);
    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "SHUTDOWN" }] },
            { merge: true });

    return excecuteCommand;
}

export function sendReboot(droneId) {
    console.log(`(Firestore) Sending 'REBOOT' command ...`);
    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "REBOOT" }] },
            { merge: true });

    return excecuteCommand;
}

export function sendKill(droneId, lastMissions) {
    lastMissions = lastMissions || [];
    console.log(`(Firestore) Killing drone ${droneId}...`);

    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "KILL" }] },
            { merge: true });

    // store the event in the last missions
    lastMissions.forEach((mission) => {
        const MissionDoc = firestoreApi.getMissionDocumentPath(mission.organizationId, mission.locationId, mission.id)
        storeEvent(MissionDoc, "DRONE_KILLED", { drone: { id: droneId } })
    })

    return excecuteCommand;
}

export function sendEmergencyLand(droneId, lastMissions) {
    lastMissions = lastMissions || [];
    console.log(`(Firestore) Sending 'EMERGENCY_LAND' command to ${droneId}...`);
    console.log(lastMissions.map(mission => mission.missionId));

    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "EMERGENCY_LAND" }] },
            { merge: true });


    // store the event in the last missions
    lastMissions.forEach((mission) => {
        const MissionDoc = firestoreApi.getMissionDocumentPath(mission.organizationId, mission.locationId, mission.missionId)
        storeEvent(MissionDoc, "EMERGENCY_LANDING", { drone: { id: droneId } })
    })
    return excecuteCommand;
}

export function sendAbort(droneId, lastMissions) {
    lastMissions = lastMissions || [];
    console.log(`(Firestore) Aborting drone ${droneId}...`);

    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "ABORT_FLIGHT" }] },
            { merge: true });

    // store the event in the last missions
    lastMissions.forEach((mission) => {
        const MissionDoc = firestoreApi.getMissionDocumentPath(mission.organizationId, mission.locationId, mission.missionId)

        storeEvent(MissionDoc, "MISSION_ABORTED", { drone: { id: droneId } })
    })

    return excecuteCommand;
}

export function sendGoToSleep(droneId) {
    console.log(`(Firestore) Sending 'GO_TO_SLEEP' command to ${droneId}...`);

    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "GO_TO_SLEEP" }] },
            { merge: true });

    return excecuteCommand;
}

export function sendWakeUp(droneId) {
    console.log(`(Firestore) Sending 'WAKE_UP' command to ${droneId}...`);

    const droneCommandDoc = firestoreApi.getDroneCommandPath(droneId);
    const excecuteCommand = getFirebaseFirestore()
        .doc(droneCommandDoc)
        .update({ commands: [{ id: short.generate(), type: "WAKE_UP" }] },
            { merge: true });

    return excecuteCommand;
}

export function sendHello(droneId) {
    console.log(`(Firestore) Sending 'HELLO' command ...`);
    executeCommands(droneId, [{ type: "HELLO" }]);
}

export function getDroneConfigPresets(setDroneConfigPresets) {
    console.log(`(Firestore) Getting drone config presets ...`);

    return getFirebaseFirestore()
        .doc(`droneConfigs/presets`)
        .get()
        .then((snapshot) => {
            const droneConfigPresets = snapshot.data();
            setDroneConfigPresets(droneConfigPresets);
            return droneConfigPresets;
        });
}

export function getDroneConfig(droneId, setDroneConfig) {
    console.log(`(Firestore) Getting drone config ...`);

    return getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .get()
        .then((snapshot) => {
            const droneConfig = snapshot.data();
            setDroneConfig(droneConfig);
            return droneConfig;
        });
}

export function getDroneFeedback(droneId, setDroneFeedback) {
    console.log(`(Firestore) Getting drone feedback ...`);

    return getFirebaseFirestore()
        .doc(`droneFeedback/${droneId}`)
        .get()
        .then((snapshot) => {
            const feedback = snapshot.data();
            setDroneFeedback(feedback);
            return feedback;
        });
}

export function getPayload(payloadId, setPayload) {
    return new firestoreApi.PayloadDocument(payloadId).get(setPayload);
}

export function subscribeDroneFeedback(droneId, setDroneFeedback) {
    console.log(`(Firestore) Subscribing to droneFeedback ...`);

    const unsubscribe = getFirebaseFirestore()
        .doc(`droneFeedbacks/${droneId}`)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving droneFeedback ...`);
            setDroneFeedback(snapshot.data());
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from droneFeedback ...`);
        unsubscribe();
    };
}

export function subscribeDroneConfig(droneId, setDroneConfig) {
    console.log(`(Firestore) Subscribing to droneConfig ...`);

    const unsubscribe = getFirebaseFirestore()
        .doc(`droneConfigs/${droneId}`)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving droneConfig ...`);
            setDroneConfig(snapshot.data());
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from droneConfig ...`);
        unsubscribe();
    };
}

export function subscribeDroneCommands(droneId, setDroneCommands) {
    console.log(`(Firestore) Subscribing to droneCommands ...`);

    const unsubscribe = getFirebaseFirestore()
        .doc(`droneCommands/${droneId}`)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving droneCommands ...`);
            setDroneCommands(snapshot.exists ? snapshot.data().commands || [] : []);
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from droneCommands ...`);
        unsubscribe();
    };
}

export function deactivateAlert(droneId, alert) {
    console.log(`(Firestore) Deactivating alert ...`);
    console.log(alert);

    const documentPath = firestoreApi.getDroneAlertDocumentPath(droneId);

    return getFirebaseFirestore()
        .doc(documentPath)
        .update({ [`alerts.${alert.level}.${alert.id}.active`]: false })
        .then(() => {
            console.log(`(Firestore) Alert deactivated.`);
        });
}

export function subscribeDroneAlerts(droneId, setAlerts) {
    console.log(`(Firestore) Subscribing to drone alerts ...`);
    const collectionPath = firestoreApi.getDroneAlertDocumentPath(droneId);

    const unsubscribe = getFirebaseFirestore()
        .doc(collectionPath)
        .onSnapshot((snapshot) => {
            console.log(`(Firestore) Receiving droneAlerts ...`);
            setAlerts(snapshot.data());
        });

    return () => {
        console.log(`(Firestore) Unsubscribing from droneAlerts ...`);
        unsubscribe();
    };
}

export function subscribeNewDroneAlerts(droneId, setAlerts) {
    return new firestoreApi.DroneAlertCollection(droneId).subscribe(setAlerts);
}

export function subscribeAllAlerts(setAllAlerts) {
    return new firestoreApi.AlertCollection().subscribe(setAllAlerts);
}

export function subscribeAllAlert(alertId, setAlert) {
    return new firestoreApi.AlertDocument(alertId).subscribe(setAlert);
}

export function subscribeRoutines(organizationId, locationId, setRoutines) {
    return new firestoreApi.RoutineCollection(organizationId, locationId).subscribe(setRoutines);
}

export function getRoutines(organizationId, locationId, setRoutines) {
    return new firestoreApi.RoutineCollection(organizationId, locationId).get(setRoutines);
}

export function subscribeResults(organizationId, locationId, missionId, setResults) {
    return new firestoreApi.ResultCollection(organizationId, locationId, missionId).subscribe(setResults);
}

export function subscribeMeasurements(organizationId, locationId, missionId, setMeasurements) {
    const measurementCollection = new firestoreApi.MeasurementCollection(organizationId, locationId);

    const measurementQuery = measurementCollection.createQuery()
        .where('meta.mission.id', '==', missionId);
    // .orderBy("meta.timestamp", "desc");

    return measurementCollection.subscribe(setMeasurements, measurementQuery);
}

export function subscribeDocks(organizationId, locationId, setDocks) {
    return new firestoreApi.DockCollection(organizationId, locationId).subscribe(setDocks);
}

export function droneIsOnline(droneFeedback) {
    const isNotTooLongAgo = (date) => {
        // Drone publishes every second, but to avoid false
        // offline status we set the check a little bit higher.

        const TIME = 5000;
        const someTimeAgo = Date.now() - TIME;
        return date >= someTimeAgo;
    };

    if (droneFeedback === undefined) return false;
    if (droneFeedback.isOnline !== undefined) return droneFeedback.isOnline;
    if (droneFeedback.lastUpdate !== undefined) return isNotTooLongAgo(droneFeedback.lastUpdate.toDate());

    return false;
}

export function droneIsFlying(status) {
    const isFlyingState = (state) => {
        return [
            "Prepare",
            "Arming",
            "Take off",
            "Calibrating",
            "Flying",
            "Returning",
            "Landing"
        ].includes(state);
    }

    return status ? isFlyingState(status.data) : false;
}

export function executeCommands(droneId, commands) {
    console.log(`(CloudFunctions) Executing commands ...`);

    // const executeCommandsPromise = getFirebaseApp()
    //     .functions("europe-west2")
    //     .httpsCallable("executeCommands");

    const executeCommandsPromise = getFirebaseFunction("executeCommands", "europe-west2")

    return executeCommandsPromise({
        droneId: droneId,
        commands: commands,
    }).then((result) => {
        console.log(`(CloudFunctions) Commands executed.`);
        console.log(result);
    }).catch((error) => {
        console.log(`(CloudFunctions) Error executing commands.`);
        console.log(error);
    });
}

export function executeTask(droneId, taskId) {
    console.log(`(CloudFunctions) Executing task ...`);

    // const executeTaskPromise = getFirebaseApp()
    //     .functions("europe-west2")
    //     .httpsCallable("executeTask");

    const executeTaskPromise = getFirebaseFunction("executeTask", "europe-west2");

    return executeTaskPromise({
        droneId: droneId,
        taskId: taskId,
    });
}

export async function postFlight(droneId, task, locationId) {
    console.log(`(CloudFunctions) Executing mission ...`);

    const url = `https://europe-west2-corvus-backend.cloudfunctions.net/clientApi/v1/location/${locationId}/flight`;

    const user = getFirebaseAuth().currentUser;
    const token = await user.getIdToken();

    const jsonPayload = {
        drone: { id: droneId },
        locationId: locationId,
        task: task,
    };

    const config = {
        headers: { authorization: `Bearer ${token}` }
    }

    return axios.post(url, jsonPayload, config)
        .catch((error) => {
            console.log(error);
        });
}

export async function postFlightBeta(droneId, task, locationId) {
    console.log(`(CloudFunctions) Executing mission ...`);
}

export async function postResumeMission(organizationId, locationId, missionId) {
    console.log(`(CloudFunctions) Resuming mission ...`);

    const url = `https://europe-west2-corvus-backend.cloudfunctions.net/executeMissionHttps`;

    const user = getFirebaseAuth().currentUser;
    const token = await user.getIdToken();

    const jsonPayload = { data: { organizationId, locationId, missionId } };

    const config = {
        headers: { authorization: `Bearer ${token}` }
    }

    return axios.post(url, jsonPayload, config)
        .catch((error) => {
            console.log(error);
        });
}

export function compileCommands(droneId, taskId) { // TODO : Rename to previewFlight
    console.log(`(CloudFunctions) Compiling commands ...`);

    try {
        const previewFlightPromise = getFirebaseFunction("previewFlightHttps", "europe-west2");
        return previewFlightPromise({
            drone: { id: droneId },
            task: { id: taskId },
        }).catch((error) => {
            // Handle the rejected promise (error) here
            console.error('Error previewing flight:', error.message);
            return { data: [] };
        });;
    } catch (error) {
        return { data: [] };
    }
}

export function compileCommandsBeta(droneId, taskId) {
    console.log(`(CloudFunctions) Compiling task ...`);
}

export function compileCommandsResume(organizationId, locationId, missionId) {
    console.log(`(CloudFunctions) Compiling task ...`);

    const compileCommandsNewPromise = getFirebaseFunction("previewFlightHttps", "europe-west2")

    return compileCommandsNewPromise({
        organization: { id: organizationId },
        location: { id: locationId },
        mission: { id: missionId },
    });
}

const FLYZONES_COLUMNS = ["name", "x_min", "y_min", "x_max", "y_max", "z_min", "z_max", "yaw"];
const MARKERS_COLUMNS = ["id", "valid", "x", "y", "z", "yaw", "width", "height", "uncertainty"];

function validateCsvColumns(csv, expectedColumns) {
    const options = {
        header: true,
        skipEmptyLines: true,
    };
    const parsedData = papa.parse(csv, options);
    const csvColumns = parsedData.meta.fields;

    const missingColumns = expectedColumns.filter(col => !csvColumns.includes(col));
    const extraColumns = csvColumns.filter(col => !expectedColumns.includes(col));

    if (missingColumns.length > 0 || extraColumns.length > 0) {
        let errorMessage = 'Invalid CSV format.';

        if (missingColumns.length > 0) {
            errorMessage += ` Missing columns: ${missingColumns.join(', ')}.`;
        }

        if (extraColumns.length > 0) {
            errorMessage += ` Extra columns: ${extraColumns.join(', ')}.`;
        }

        throw new Error(errorMessage);
    }
}

export function setFlyzonesFromCsv(organizationId, locationId, flyzonesCsv) {
    validateCsvColumns(flyzonesCsv, FLYZONES_COLUMNS);

    const options = {
        columns: FLYZONES_COLUMNS,
        header: true,
        skipEmptyLines: true,
    };
    const flyzones = papa.parse(flyzonesCsv, options).data;
    return setFlyzones(organizationId, locationId, flyzones);
}

export function setMarkersFromCsv(organizationId, locationId, markersCsv) {
    validateCsvColumns(markersCsv, MARKERS_COLUMNS);

    const options = {
        columns: MARKERS_COLUMNS,
        header: true,
        skipEmptyLines: true,
    };
    const markers = papa.parse(markersCsv, options).data;
    return setMarkers(organizationId, locationId, markers);
}

export function setAreasFromJson(organizationId, locationId, areasJson) {
    return setAreas(organizationId, locationId, areasJson);
}

export async function getAreasAsJson(organizationId, locationId) {
    return { areas: await getAreas(organizationId, locationId) };
}

export async function getMarkersAsCsv(organizationId, locationId) {
    const markers = await getMarkers(organizationId, locationId);
    let markersCsv = papa.unparse(markers, { columns: MARKERS_COLUMNS, header: false });
    markersCsv = MARKERS_COLUMNS.join(',') + '\n' + markersCsv;
    return markersCsv;
}

export async function getFlyzonesAsCsv(organizationId, locationId) {
    const flyzones = await getFlyzones(organizationId, locationId);
    let flyzonesCsv = papa.unparse(flyzones, { columns: FLYZONES_COLUMNS, header: false });
    flyzonesCsv = FLYZONES_COLUMNS.join(',') + '\n' + flyzonesCsv
    return flyzonesCsv;
}

export function downloadResult(organizationId, locationId, missionId, resultId, fileName) {
    const resultPath = `${firestoreApi.getResultDocumentPath(organizationId, locationId, missionId, resultId)}/${fileName}`;

    return getFirebaseStorageDownloadUrl(resultPath)
        .then((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank'; // Open in a new tab or window
            link.download = fileName; // Set the download attribute with the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
}

export function storeEvent(firestorePath, eventType, eventData) {

    return getFirebaseFirestore()
        .doc(firestorePath)
        .update(
            {
                events: arrayUnion({
                    ...eventData,
                    type: eventType,
                    timestamp: new Date(),
                }),
            },
            { merge: true }
        )
        .catch((error) => {
            console.error(
                `Error while storing event '${eventType}' in Firestore document '${firestorePath}'`
            );
            console.error(error);
        });
}

export function storeResumeEvent(organizationId, loacationId, missionId) {
    const MissionDoc = firestoreApi.getMissionDocumentPath(organizationId, loacationId, missionId)
    storeEvent(MissionDoc, "RESUME_MISSION", {})
}

export async function getAppSettings(organizationId, loacationId, taskId, appId, setAppSettings) {

    // load the default app settings
    let appSettings = await getFirebaseFirestore().doc(`apps/${appId}`).get().then((snapshot) => {
        return snapshot.data().settings;
    });

    // load the locations default settings
    let appLocationsSettings = await getFirebaseFirestore().doc(`organizations/${organizationId}/locations/${loacationId}`).get().then((snapshot) => {
        const apps = snapshot.data().apps;
        for (let i = 0; i < apps.length; i++) {
            if (apps[i].id === appId) {
                return apps[i]?.settings || {};
            }
        }
    });

    // load the task spesific settings
    let appTaskSettings = await getFirebaseFirestore().doc(`organizations/${organizationId}/locations/${loacationId}/tasks/${taskId}`).get().then((snapshot) => {
        const apps = snapshot.data().apps;
        for (let i = 0; i < apps.length; i++) {
            if (apps[i].id === appId) {
                return apps[i]?.settings || {};
            }
        }
    });
    setAppSettings({ app: appSettings, location: appLocationsSettings, task: appTaskSettings });
}

export async function updateAppSettings(organizationId, loacationId, taskId, appId, appSettings) {
    const taskPath = `organizations/${organizationId}/locations/${loacationId}/tasks/${taskId}`;
    let task_doc = await getFirebaseFirestore().doc(taskPath).get().then((snapshot) => snapshot.data());

    let appList = task_doc?.apps || [];;
    let appIdx = -1;
    for (let i = 0; i < appList.length; i++) {
        if (appList[i].id === appId) {
            appIdx = i;
        }
    }
    if (appIdx >= 0) {
        appList[appIdx].settings = appSettings;

        return getFirebaseFirestore()
            .doc(taskPath)
            .update(
                {
                    apps: appList,
                },
                { merge: true }
            )
            .catch((error) => {
                console.error(
                    `Error while storing app settings in Firestore document '${taskPath}'`
                );
                console.error(error);
            });
    }
    return
}

export async function setAppPending(organizationId, locationId, missionId, appId) {
    const missionPath = `organizations/${organizationId}/locations/${locationId}/missions/${missionId}`;
    return getFirebaseFirestore().doc(missionPath).get().then((snapshot) => {
        const apps = snapshot.data().apps;
        const appIdx = apps.map((app) => app.id).indexOf(appId);
        apps[appIdx]["state"] = STATE_PENDING;
        getFirebaseFirestore().doc(missionPath).update({ apps: apps });
    });
}

function getMissionHandlerUrl(organizationId, locationId) {
    const locationPath = firestoreApi.getLocationDocumentPath(organizationId, locationId);
    return getFirebaseFirestore().doc(locationPath).get().then((snapshot) => {
        const missionHandlerVersion = snapshot.data().missionHandler || "";
        if (missionHandlerVersion) {
            return `https://europe-west1-corvus-backend.cloudfunctions.net/missionhandler-${missionHandlerVersion}`
        } else {
            return "https://europe-west1-corvus-backend.cloudfunctions.net/missionhandler"
        }
    });
}


export async function restartMissionHandler(organizationId, locationId, missionId, noFly) {
    const missionPath = `organizations/${organizationId}/locations/${locationId}/missions/${missionId}`;
    getFirebaseFirestore().doc(missionPath).get().then(async (snapshot) => {
        if (!snapshot.data().running) {
            console.log("starting mission handler");
            // state the mission handler
            const user = getFirebaseAuth().currentUser;
            const token = await user.getIdToken();

            const url = await getMissionHandlerUrl(organizationId, locationId);
            console.log(url);
            const jsonPayload = {
                mission: {
                    organization: { id: organizationId },
                    location: { id: locationId },
                    mission: { id: missionId },
                }
            }
            if (noFly) {
                jsonPayload.mission.nofly = true;
            }

            const config = {
                headers: { authorization: `Bearer ${token}` }
            }

            axios.post(url, jsonPayload, config)
                .catch((error) => {
                    console.log(error);
                });
        }
    });
}

export async function startApplication(organizationId, locationId, missionId, appId) {
    const user = getFirebaseAuth().currentUser;
    const token = await user.getIdToken();

    const url = "https://europe-west1-corvus-backend.cloudfunctions.net/start-app"
    const jsonPayload = {
        mission: {
            organization: { id: organizationId },
            location: { id: locationId },
            mission: { id: missionId },
            app: { id: appId }
        }
    }

    const config = {
        headers: { authorization: `Bearer ${token}` }
    }

    axios.post(url, jsonPayload, config)
        .catch((error) => {
            console.log(error);
        });
}

export function subscribeLivestream(droneId, setLivestreams) {
    const livestreamDocument = new firestoreApi.LivestreamDocument(droneId);

    return livestreamDocument.subscribe((data) => {
        setLivestreams(data);
    });
}