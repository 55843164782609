import React from "react";
import { Alert, Button, Card } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, {
    DockListBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";

export default function DockListPage() {
    const history = useHistory();
    const [docks, setDocks] = React.useState([]);
    const { organizationId, locationId } = useParams();

    if (!locationId || locationId === "undefined") {
        history.push(urls.locations(organizationId));
    }

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getDocks(organizationId, locationId, setDocks);
        }
    }, [organizationId, locationId]);

    return (
        <Layout>
            <DockListBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Docks
                    </>
                }>
                    <div>
                        <Button
                            className="d-inline ms-2 ms-2"
                            variant="outline-success"
                            as={Link}
                            to={urls.dockCreate(organizationId, locationId)}
                        >
                            <FaPlus className="me-2" />
                            New Dock
                        </Button>
                    </div>
                </Title>
                {docks.length === 0 && (
                    // TODO : Move this functionalty to a component and reuse it in DroneListPage & RoutineListPage
                    <Alert variant="primary" className="mx-4 mb-4">
                        No docks
                    </Alert>
                )}
                {docks.length > 0 && (
                    <CorvusList>
                        {docks.map((dock) => (
                            <CorvusListItem
                                className="p-0"
                                key={dock.id}
                                as={Link}
                                to={urls.dock(organizationId, locationId, dock.id)}
                            >
                                <CorvusListItemText>
                                    Dock{' '}<code className="mx-2">{dock?.name && dock.name !== '' ? dock.name : dock.id}</code>{' '}at{' '}<code className="mx-2">({dock.position.x}x{', '}{dock.position.y}y{', '}{dock.position.z}z{', '}{dock.yaw} yaw)</code>
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                    </CorvusList>
                )}
            </Card>
        </Layout>
    );
}
