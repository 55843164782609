import React from "react";
import { Button, ButtonGroup, Card, Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { FaDownload, FaGripHorizontal, FaImage, FaMap } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import * as firestoreApi from "../../api/firestoreApi.js";
import CodeBlock from "../../components/CodeBlock";
import DevMenuButton from "../../components/DevMenuButton";
import MapButton from "../../components/MapButton";
import FirestoreDevMenuItem from "../../components/FirestoreDevMenuItem";
import { FlightTagger } from "../../components/ItemTagger";
import Layout, { FlightBreadcrumbContainer, Title } from "../../components/Layout/Layout";
import LogDevMenuItem from "../../components/LogDevMenuItem";
import StorageDevMenuItem from "../../components/StorageDevMenuItem";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseStorageDownloadUrl } from "../../corvusFirebase";
import urls from "../../urls.js";
import TimeDisplay from '../../components/TimeDisplay/index.jsx';

function realDownloadDevdataTemp(organizationId, locationId, flightId) {
    const flightPath = firestoreApi.getFlightDocumentPath(organizationId, locationId, flightId);
    const bucketUrl = `gs://corvus-backend.appspot.com/${flightPath}/dev/telemetry.zip`;

    return getFirebaseStorageDownloadUrl(bucketUrl)
        .then((url) => {
            window.open(url, '_blank');
        });
}
/*
function realDownloadDevdata(organizationId, locationId, flightId) {
    // TODO: rename this function to something better
    const flightPath = firestoreApi.getFlightDocumentPath(organizationId, locationId, flightId);
    const bucketUrl = `gs://corvus-backend.appspot.com/${flightPath}/dev/telemetry.zip`;

    return getFirebaseStorageDownloadUrl(bucketUrl)
        .then((url) => {
            window.open(url, '_blank');
        });
}
*/

export default function FlightPage() {
    const commandStates = [
        `scheduled`,  // Drone scheduled command
        `started`,    // Drone started executing command
        `failed`,     // Drone unsuccessfully finished executed command
        `canceled`,   // Drone got telled to cancel (abort & delete) command by the backend
        `succeeded`,  // Drone successfully finished executed command
    ];

    const { organizationId, locationId, flightId } = useParams();
    const [flight, setFlight] = React.useState();
    const { getUserIsAdmin } = useUser();
    const history = useHistory();

    React.useEffect(() => {
        if (organizationId && locationId && flightId) {
            return api.subscribeFlight(organizationId, locationId, flightId, setFlight);
        } else if (!organizationId && !locationId && flightId) {
            return api.subscribeFlight2(flightId, (mission) => {
                history.push(urls.flight(mission.organization.id, mission.location.id, flightId));
            });
        }
    }, [organizationId, locationId, flightId, history]);

    // // Load flight from firestore
    // React.useEffect(() => {
    //     return api.subscribeFlight(organizationId, locationId, flightId, setFlight);
    // }, [organizationId, locationId, flightId]);

    function viewTelemetry() {
        const flightPath = firestoreApi.getFlightDocumentPath(organizationId, locationId, flightId);

        const bucketPath = `gs://corvus-backend.appspot.com/${flightPath}/dev/telemetry/report/report.html`;

        getFirebaseStorageDownloadUrl(bucketPath)
            .then((url) => {
                window.open(url, '_blank');
            });
    }

    function downloadDevdata() {
        // return realDownloadDevdata(organizationId, locationId, flightId);
        return realDownloadDevdataTemp(organizationId, locationId, flightId);
    }

    function getFirebaseStoragePath() {
        return firestoreApi.getFlightDocumentPath(organizationId, locationId, flightId);
    }

    function getFirestorePath() {
        return firestoreApi.getFlightDocumentPath(organizationId, locationId, flightId);
    }

    return (
        <Layout>
            <FlightBreadcrumbContainer />
            <Card className="mb-4">
                <Title title={
                    <>
                        {/* <BackButton href={urls.flights(organizationId, locationId)} /> */}
                        Flight <TimeDisplay
                            timestamp={flight?.timestamp?.seconds}
                            format="YYYY-MM-DD HH:mm:ss"
                            organizationId={organizationId}
                            locationId={locationId}
                        /> by {flight?.drone?.id}
                    </>
                }>
                    <div>
                        <MapButton
                            url={`${urls.locationMap(organizationId, locationId)}?flightId=${flightId}`}
                            className="ms-2"
                        />
                        {getUserIsAdmin() && (
                            <ButtonGroup className="ms-2">
                                <DevMenuButton>
                                    <Dropdown.Item
                                        as={Link}
                                        to={`${urls.locationMap(organizationId, locationId)}?flightId=${flightId}`}
                                        target="_blank"
                                    >
                                        <FaMap
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        View Map
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={viewTelemetry}
                                        // disabled={!flight?.telemetry || true}
                                        disabled={false}
                                    >
                                        <FaGripHorizontal
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        View Telemetry
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={downloadDevdata}
                                        // disabled={!flight?.telemetry || true}
                                        disabled={false}
                                    >
                                        <FaDownload
                                            className="me-2"
                                            style={{
                                                marginTop: "-2px",
                                            }}
                                        />
                                        Telemetry
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item disabled>
                                <FaDownload
                                    className="me-2"
                                    style={{
                                        marginTop: "-2px",
                                    }}
                                />
                                Measurements
                            </Dropdown.Item> */}
                                    <Dropdown.Divider />
                                    <FirestoreDevMenuItem
                                        as={Dropdown.Item}
                                        path={getFirestorePath()}
                                    />
                                    <StorageDevMenuItem
                                        as={Dropdown.Item}
                                        path={getFirebaseStoragePath()}
                                    />
                                    <LogDevMenuItem
                                        as={Dropdown.Item}
                                        name='flight'
                                        value={flightId}
                                    />
                                </DevMenuButton>
                            </ButtonGroup>
                        )}
                    </div>
                </Title>
                <ListGroup variant="flush">
                    {getUserIsAdmin() && (
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Tags</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    <FlightTagger
                                        selected={flight?.tags || []}
                                        onChange={(newTags) => api.updateFlightTags(organizationId, locationId, flightId, newTags)}
                                    />
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                    {getUserIsAdmin() && flight?.commands && (
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Commands</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    {commandStates.map((commandState, index) => {
                                        const commandStateCount = flight.commands.filter(command => command?.status === commandState).length;
                                        return (
                                            <Button
                                                key={index}
                                                variant={commandStateCount > 0 ? 'secondary' : 'outline-secondary'}
                                                size='sm'
                                                className="me-2"
                                            >
                                                {commandStateCount} {commandState}
                                            </Button>
                                        );
                                    })}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item className="px-4">
                        <Row>
                            <Col xs={12} md={3}>
                                <b>Status</b>
                            </Col>
                            <Col xs={12} md={9}>
                                <h5 className='d-inline'>
                                    <Button
                                        variant={flight?.measurements?.length ? 'secondary' : 'outline-secondary'}
                                        as={Link}
                                        to={urls.flightMeasurements(organizationId, locationId, flightId)}
                                        size='sm'
                                        className="me-2"
                                    >
                                        <FaImage className="me-2" />
                                        {flight?.measurements?.length || 0}
                                    </Button>
                                </h5>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {flight?.mission?.id && (
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Mission</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    <h5 className='d-inline'>
                                        <Button
                                            variant='secondary'
                                            as={Link}
                                            to={urls.locationMission(organizationId, locationId, flight.mission.id)}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {/* <FaImage className="me-2" /> */}
                                            {flight.mission.id}
                                        </Button>
                                    </h5>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                    {flight?.drone?.id && (
                        <ListGroup.Item className="px-4">
                            <Row>
                                <Col xs={12} md={3}>
                                    <b>Drone</b>
                                </Col>
                                <Col xs={12} md={9}>
                                    <h5 className='d-inline'>
                                        <Button
                                            variant='secondary'
                                            as={Link}
                                            to={urls.locationDrone(organizationId, locationId, flight.drone.id)}
                                            size='sm'
                                            className="me-2"
                                        >
                                            {flight.drone.id}
                                        </Button>
                                    </h5>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                </ListGroup>
                {getUserIsAdmin() &&
                    <Card.Body className='p-4'>
                        <CodeBlock name='Flight' code={flight} />
                    </Card.Body>
                }
            </Card>
        </Layout >
    );
}   
