import React from "react";
import {
    Alert, Button, Card, Container, Form, FormControl
} from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth_provider";
import urls from "../../urls.js";

export default function LoginPage() {
    const history = useHistory();
    const location = useLocation();
    const [remember, setRemember] = React.useState(false);
    const emailRef = React.useRef("");
    const passwordRef = React.useRef("");
    const [error, setError] = React.useState();
    const { user, login } = useAuth();
    const { from } = location.state || { from: { pathname: "/" } };

    if (user) {
        history.push(from.pathname);
    };

    function toggleRemember() {
        setRemember(!remember);
    }

    function doLogin(event) {
        event.preventDefault();

        login(emailRef.current.value, passwordRef.current.value, remember)
            .then(() => {
                history.push(from.pathname);
            })
            .catch((loginError) => {
                setError(loginError.message);
            });
    }

    return (
        <>
            <div className="my-5 text-center">
                <img
                    onClick={() => history.push(urls.home())}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                    src={process.env.PUBLIC_URL + "/logo-black-white.png"}
                    alt="none"
                />
            </div>
            <Container style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        {error && (
                            <Alert
                                variant="danger"
                                className="mb-4"
                                onClose={() => setError(undefined)}
                                dismissible
                            >
                                <FaExclamationCircle
                                    className="me-2 text-danger"
                                    style={{ marginTop: "-2px" }}
                                />
                                {error}
                            </Alert>
                        )}
                        <Form onSubmit={doLogin}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <b>Email address</b>
                                </Form.Label>
                                <FormControl type="email" ref={emailRef} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="">
                                    <b>Password</b>
                                </Form.Label>
                                <FormControl
                                    type="password"
                                    ref={passwordRef}
                                />
                            </Form.Group>
                            <Form.Group
                                className="py-3"
                                controlId="formBasicCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Remember me"
                                    onClick={toggleRemember}
                                    defaultChecked={remember}
                                />
                            </Form.Group>
                            <div className="d-grid gap-2">
                                <Button type="submit" onClick={doLogin} block>
                                    <b>Login</b>
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <div className="text-center mt-3">
                Can't login?{" "}
                <Link to={urls.resetPassword()}>
                    Reset password
                </Link>
            </div>
            {/* <div className="text-center mt-3">
                Don't have an account?{" "}
                <a href="#" onClick={() => history.push(`/signup`)}>
                    Sign up
                </a>
            </div> */}
        </>
    );
}
