import React from "react";
import { Badge, ListGroup } from "react-bootstrap";
import { FaChevronRight, FaImage, FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import * as missionApi from "../../api/missionApi";
import MissionStatusMessage from "../../components/MissionStatusMessage";
import MissionActivityBadge from "../MissionActivityBadge";
import MeasurementInfoString from "../MeasurementInfo"

export function MissionMeasurementBadge(props) {

    function getCount() {
        return props.mission.measurements?.length || 0;
    }

    function getColor() {
        const measurementCount = getCount();

        if (missionApi.getMissionActivity(props.mission) === missionApi.STATE_SUCCEEDED ||
            missionApi.getMissionActivity(props.mission) === missionApi.STATE_FAILED ||
            missionApi.getMissionActivity(props.mission) === missionApi.STATE_CANCELLED ||
            missionApi.getMissionActivity(props.mission) === 'finished') {
            if (measurementCount > 0) return 'success';
            else return 'secondary';
        }

        return 'primary';
    }

    return (
        <Badge pill bg={getColor()} className={props.className}>
            <FaImage className="me-1" style={{marginTop: "-2px"}}/>
            <MeasurementInfoString mission={props.mission} />
        </Badge>
    );
}

function MissionResultsBadge({ mission, className }) {
    const [count, setCount] = React.useState(0);
    const [results, setResults] = React.useState([]);

    function getAppResults(app, results) {
        return results.filter(result => result.app.id === app.id);
    }

    React.useEffect(() => {
        return api.subscribeResults(mission.organizationId, mission.locationId, mission.id, setResults);
    }, [mission]);

    React.useEffect(() => {
        const totalResults = missionApi.getMissionApps(mission).reduce((acc, app) => {
            const appResults = getAppResults(app, results);
            const countForApp = appResults.filter(result => result.fileName).length;
            return acc + countForApp;
        }, 0);
        setCount(totalResults);
    }, [mission, results]);

    return (
        <span>
            {count > 0 && (
                <Badge pill bg="success" className={className}>
                    <FaFileAlt className="me-1" style={{marginTop: "-2px"}}/>
                    {count}
                </Badge>
            )}
        </span>
    )
}

export default function MissionListItem(props) {
    return (
        <ListGroup.Item
            className="px-4 py-2 d-flex justify-content-between align-items-start"
            key={props.key}
            as={Link}
            to={props.to}
            action
        >
            <div>
                <MissionStatusMessage
                    mission={props.mission}
                >
                    {props.children}
                </MissionStatusMessage>
            </div>
            <div>
                {props.showActivity && (
                    <MissionActivityBadge
                        className="me-1"
                        mission={props.mission}
                    />
                )}
                {<MissionResultsBadge
                    className='me-1'
                    mission={props.mission}
                />}
                {props.showMeasurements && (
                    <MissionMeasurementBadge
                        className='me-1'
                        mission={props.mission}
                    />
                )}
                <FaChevronRight style={{ color: "#b9b9b9" }} />
            </div>
        </ListGroup.Item>
    );
}
