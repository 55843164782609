import React from "react";
import { Alert, Badge, Button, Card } from "react-bootstrap";
import Countdown from "react-countdown";
import { FaBan, FaClock, FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList
} from "../../components/CorvusList";
import Layout, {
    RoutineListBreadcrumbContainer, Title
} from "../../components/Layout/Layout";
import ListItemWithToggle from "../../components/ListItemWithToggle";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";
var parser = require("cron-parser");

function RoutineItemWithToggle(props) {
    const { organizationId, locationId } = useParams();
    const { user } = useUser();

    const [currentTime, setCurrentTime] = React.useState(
        getDate(props.routine.interval)
    );

    function getDate(interval) {
        const intervalIterator = parser.parseExpression(interval);
        return intervalIterator.next()._date;
    }

    function setRoutineEnabled(enabled) {
        const promises = [
            api.updateRoutine(organizationId, locationId, props.routine.id, { enabled }),
        ];

        if (enabled) {
            promises.push(
                api.updateRoutine(organizationId, locationId, props.routine.id, {
                    enabler: user.uid,
                })
            );
        }

        return promises;
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <>
                {completed && "Starting"}
                {days > 0 && `${days}d `}
                {hours > 0 && `${hours}h `}
                {minutes > 0 && `${minutes}m `}
                {seconds > 0 && `${seconds}s `}
            </>
        );
    };

    return (
        <ListItemWithToggle
            key={props.routine.id}
            item={props.routine}
            text={props.routine.name || 'Unnamed Routine'}
            enabled={props.routine.enabled}
            onDisable={() => setRoutineEnabled(false)}
            onEnable={() => setRoutineEnabled(true)}
            to={urls.routine(organizationId, locationId, props.routine.id)}
            badges={
                props.enabled === true ? (
                    <Badge pill bg='primary' className='me-1'>
                        <FaClock className='me-2' style={{marginTop: "-2px"}}/>
                        <Countdown
                            className='d-inline'
                            date={currentTime}
                            key={currentTime}
                            renderer={renderer}
                            autoStart={true}
                            intervalDelay={0}
                            onComplete={() => {
                                // Wait 1 second before updating the current time to ensure we set it to the next interval
                                setTimeout(() => {
                                    setCurrentTime(
                                        getDate(props.routine.interval)
                                    );
                                }, 1000);
                            }}
                        />
                    </Badge>
                ) : (
                    <Badge pill bg='secondary' className='me-1'>
                        <FaBan className='mx-1' />
                    </Badge>
                )
            }
        />
    );
}

export default function RoutineListPage(props) {
    const { getUserIsAdmin } = useUser();
    const history = useHistory();
    const { organizationId, locationId } = useParams();
    const [routines, setRoutines] = React.useState([]);
    const [location, setLocation] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);

    if (!locationId || locationId === "undefined") {
        history.push(urls.locations(organizationId));
    }

    // Subscribe to location
    React.useEffect(() => {
        if (getUserIsAdmin()) {
            return api.subscribeLocation(organizationId, locationId, setLocation);
        }
    }, [getUserIsAdmin, organizationId, locationId]);

    React.useEffect(() => {
        if (organizationId) {
            return api.subscribeRoutines(organizationId, locationId, setRoutines);
        }
    }, [organizationId, locationId]);

    React.useEffect(() => {
        if (organizationId) {
            api.getTasks(organizationId, locationId, setTasks);
        }
    }, [organizationId, locationId]);

    return (
        <Layout>
            <RoutineListBreadcrumbContainer />
            <Card>
                <Title title={
                    <>
                        <BackButton href={urls.location(organizationId, locationId)} />
                        Routines
                    </>
                }>
                    <Button
                        variant="outline-success"
                        as={Link}
                        to={urls.routineCreate(organizationId, locationId)}
                    >
                        <FaPlus className="me-2" />
                        Add routine
                    </Button>
                </Title>
                {console.log(location)}
                {(location && (!location.assistant || !location.assistant.enabled)) && (
                    <Alert variant="danger" className="mx-4 mb-4">
                        Assistant is not enabled for this location. Routines won't work.
                    </Alert>
                )}
                {routines.length === 0 && (
                    <Alert variant="primary" className="mx-4 mb-4">
                        No routines
                    </Alert>
                )}
                {routines.length > 0 && (
                    <CorvusList>
                        {routines.map((routine) => (
                            <RoutineItemWithToggle
                                key={routine.id}
                                tasks={tasks}
                                routine={routine}
                                text={`Routine 123`}
                                enabled={routine.enabled}
                                onDisable={() => console.log("disabling ...")}
                                onEnable={() => console.log("enabling ...")}
                                to={urls.routine(organizationId, locationId, routine.id)}
                            />
                        ))}
                    </CorvusList>
                )}
            </Card>
        </Layout>
    );
}
