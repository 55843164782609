import classnames from "classnames";
import React from "react";
import { ListGroup } from "react-bootstrap";
import CorvusRightIcon from "./CorvusRightIcon";

export function CorvusListItemText(props) {
    return (
        <div
            onClick={props.onClick}
            className={classnames("py-2 px-4", props.className)}
        >
            {props.children}
        </div>
    );
}

export function CorvusListItem(props) {
    return (
        <ListGroup.Item
            as={props.as || "div"}
            to={props.to}
            action={props.to ? true : false}
            className={classnames(
                "d-flex justify-content-between align-items-start",
                props.className
            )}
            style={props.style}
        >
            {props.children}
            <CorvusRightIcon className="py-2 px-4" />
        </ListGroup.Item>
    );
}

export function CorvusList(props) {
    return (
        <ListGroup className={props.className} variant="flush">
            {props.children}
        </ListGroup>
    );
}
