import GenericFormGroup from './GenericFormGroup';
import { Form, InputGroup } from 'react-bootstrap';

const TextFormGroup = (props) => {
    return (
        <GenericFormGroup
            {...props}
            renderInput={(field, canResetToDefault, errorMessage) => (
                <>
                    <Form.Control
                        type="text"
                        placeholder={props.placeholder}
                        {...field}
                        isInvalid={!!errorMessage}
                        style={{ fontWeight: canResetToDefault ? 'bold' : 'normal' }}
                    />
                    {props.unitText && (
                        <InputGroup.Text>{props.unitText}</InputGroup.Text>
                    )}
                </>
            )}
        />
    );
};

export default TextFormGroup;
