import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as api from "../../api/api";

export default function DroneSelector(props) {
    const [drones, setDrones] = React.useState([]);
    const { organizationId, locationId } = useParams();

    React.useEffect(() => {
        api.getDroneConfigs(organizationId, locationId, setDrones);
    }, [organizationId, locationId]);

    return (
        <Form.Select
            value={props.value}
            onChange={(event) => {
                props.onChange(event.target.value)
            }}
        >
            <option disabled value="none">
                Select a drone ...
            </option>
            {drones.map((drone) => {
                return (
                    <option
                        key={drone.id}
                        value={drone.id}
                    >
                        {drone.id}
                    </option>
                );
            })}
            <option value="any">
                Any
            </option>
        </Form.Select>
    );
}
