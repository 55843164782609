import React from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as api from "../../api/api";

function CorvusTagger(props) {
    const ref = React.createRef();
    const [corvusTags, setCorvusTags] = React.useState([]);

    React.useEffect(() => {
        async function getCorvusTags() {
            await api.getCorvusTags((allTags) => {
                setCorvusTags(allTags[props.name]);
            });
        }
        getCorvusTags();
    }, []);

    return (
        <Typeahead
            id='tag-selector'
            ref={ref}
            labelKey='name'
            multiple={true}
            options={corvusTags}
            placeholder={`Tag this ${props.name} ...`}
            allowNew={true}
            caseSensitive={false}
            newSelectionPrefix='Add a new tag: '
            selected={props.selected || []}
            onChange={props.onChange}
        />
    );
};

export function MeasurementTagger(props) {
    return (
        <CorvusTagger
            name='measurement'
            className={props.className}
            selected={props.selected}
            onChange={props.onChange}
        />
    );
}

export function FlightTagger(props) {
    return (
        <CorvusTagger
            name='flight'
            className={props.className}
            selected={props.selected}
            onChange={props.onChange}
        />
    );
}
