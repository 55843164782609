import React from "react";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa";

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import TextFormGroup from "../../../components/FormGroups/TextFormGroup.jsx";
import BackButton from "../../../components/BackButton";
import CancelButton from "../../../components/CancelButton"
import urls from "../../../urls.js";
import * as api from "../../../api/api";

const numberFromString = z
    .union([z.string(), z.number()])
    .nullable()
    .transform((val) => {
        // If the value is null or undefined or empty, return undefined
        if (val === null || val === undefined || String(val).length == 0) return undefined;
        // Convert non-empty strings to numbers
        const parsed = parseFloat(val);
        // Check if the parsed value is a valid number
        if (!isNaN(parsed)) return parsed;
        // If parsing fails, raise an error
        return { message: 'Value must be a number or empty' }
    })
    .refine((val) => typeof val === 'number' || val === undefined, {
        message: 'Value must be a number or empty',
    })
    .optional();

const settingShema = z.object({
    report: z.object({
        tray_width: numberFromString,
        tray_length: numberFromString,
        plugs_per_tray: numberFromString,
        usable_threshold: numberFromString,
    })
});

function removeUndefinedFields(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(removeUndefinedFields);
    }

    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
        const cleanedValue = removeUndefinedFields(value);
        if (cleanedValue !== undefined) {
            newObj[key] = cleanedValue;
        }
    }
    return Object.keys(newObj).length > 0 ? newObj : {};
}

export default function SampleSeedlingsSettings(props) {
    const organizationId = props.organizationId;
    const locationId = props.locationId;
    const taskId = props.taskId;
    const appId = props.appId;

    const [appSettings, setAppSettings] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();

    React.useEffect(() => {
        setIsLoading(true);
        api.getAppSettings(organizationId, locationId, taskId, appId, (appSettings) => {
            setAppSettings({ ...appSettings.app, ...appSettings.location });
            reset(appSettings.task);
            setIsLoading(false);
        });

    }, []);

    const { control, handleSubmit, reset, watch, setValue, formState: { errors, isDirty, isSubmitting } } = useForm({
        resolver: zodResolver(settingShema)
    });

    const onSubmit = async (settingsUpdate) => {
        const cleanSettings = removeUndefinedFields(settingsUpdate);
        console.log("update");
        console.log(cleanSettings);
        if (cleanSettings != undefined) {
            api.updateAppSettings(organizationId, locationId, taskId, appId, cleanSettings);
        }
        history.push(urls.task(organizationId, locationId, taskId));
    }

    return (<Card.Body className="p-4 py-0">
        {isLoading ? (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <TextFormGroup label="Tray width"
                    name="report.tray_width"
                    control={control}
                    errors={errors}
                    placeholder={`Leave empty to use location default: ${appSettings.report.tray_width}`}
                    defaultValue={undefined}
                    setValue={setValue}
                    unitText="cm" />
                <TextFormGroup label="Tray length"
                    name="report.tray_length"
                    control={control}
                    errors={errors}
                    placeholder={`Leave empty to use location default: ${appSettings.report.tray_length}`}
                    defaultValue={undefined}
                    setValue={setValue}
                    unitText="cm" />
                <TextFormGroup label="Plugs per tray"
                    name="report.plugs_per_tray"
                    control={control}
                    errors={errors}
                    placeholder={`Leave empty to use location default: ${appSettings.report.plugs_per_tray}`}
                    defaultValue={undefined}
                    setValue={setValue}
                />
                <TextFormGroup label="Usable threshold"
                    name="report.usable_threshold"
                    control={control}
                    errors={errors}
                    placeholder={`Leave empty to use location default: ${appSettings.report.usable_threshold}`}
                    defaultValue={undefined}
                    setValue={setValue}
                    unitText="mm²" />
                <Button variant="success" disabled={isSubmitting || !isDirty} type="submit" className="mb-3 mt-1">
                    <FaSave className="mb-1 me-1" />
                    {isSubmitting ? "Saving..." : "Save"}
                </Button>
                <CancelButton
                    className="ms-2 mb-3 mt-1"
                    href={urls.task(organizationId, locationId, taskId)}
                />
            </Form>)}
    </Card.Body>)
}