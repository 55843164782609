import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import CorvusSetting from "../../../components/CorvusSetting";

export default function XYZCommand(props) {
    const setX = (event) =>
        props.updateCommandSettings("x", event.target.value ? parseFloat(event.target.value) : '');
    const setY = (event) =>
        props.updateCommandSettings("y", event.target.value ? parseFloat(event.target.value) : '');
    const setZ = (event) =>
        props.updateCommandSettings("z", event.target.value ? parseFloat(event.target.value) : '');
    const setVelocity = (event) =>
        props.updateCommandSettings("velocity", event.target.value ? parseFloat(event.target.value) : '');
    const setYaw = (event) =>
        props.updateCommandSettings("yaw", event.target.value ? parseFloat(event.target.value) : '');
    const setAction = (event) =>
        props.updateCommandSettings("action", event.target.value);
    const setDelay = (event) =>
        props.updateCommandSettings("delay", event.target.value ? parseFloat(event.target.value) : '');

    return (
        <>
            <Form.Group>
                <CorvusSetting name='X'>
                    <Form.Control
                        autoFocus
                        {...props.register("command_xyz_x", { onChange: setX })}
                        defaultValue={props.command.settings.x}
                    // onChange={setX}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Y'>
                    <Form.Control
                        {...props.register("command_xyz_y", { onChange: setY })}
                        defaultValue={props.command.settings.y}
                    // onChange={setY}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Z'>
                    <Form.Control
                        defaultValue={props.command.settings.z}
                        {...props.register("command_xyz_z", { onChange: setZ })}
                    // onChange={setZ}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Speed'>
                    <Form.Control
                        defaultValue={props.command.settings.velocity}
                        {...props.register("command_xyz_velocity", { onChange: setVelocity })}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Yaw'>
                    <Form.Control
                        defaultValue={props.command.settings.yaw}
                        {...props.register("command_xyz_yaw", { onChange: setYaw })}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group>
                <CorvusSetting name='Delay'>
                    <Form.Control
                        defaultValue={props.command.settings.delay}
                        {...props.register("command_xyz_delay", { onChange: setDelay })}
                    />
                </CorvusSetting>
            </Form.Group>
            <Form.Group className="m-0">
                <CorvusSetting name='Action'>
                    <Form.Select
                        value={props.command.settings.action}
                        {...props.register("command_xyz_action", { onChange: setAction })}
                    // onChange={setAction}
                    >
                        <option value={"NO_ACTION"}>No action</option>
                        <option value={"TAKE_PICTURE"}>Take picture</option>
                        <option value={"START_BURST"}>Start burst</option>
                        <option value={"STOP_BURST"}>Stop burst</option>
                    </Form.Select>
                </CorvusSetting>
            </Form.Group>
        </>
    );
}
