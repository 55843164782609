import React from "react";
import { Badge } from "react-bootstrap";
import * as missionApi from "../../api/missionApi";

export default function MissionActivityBadge(props) {
    const missionActivity = missionApi.getMissionActivity(props.mission);
    
    function getColor() {
        switch (missionActivity) {
            case missionApi.STATE_PENDING:
            case missionApi.STATE_CANCELLED:
            case 'waiting':
                return 'secondary';
            case missionApi.STATE_SUCCEEDED:
            case 'finished':
                return 'success';
            case missionApi.STATE_FAILED:
                return 'danger';
            default:
                return 'primary'
        }
    }

    return (
        <Badge pill bg={getColor()} className={props.className}>
            {missionActivity}
        </Badge>
    );
}
