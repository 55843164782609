import React from "react";
import { Alert, Card, ListGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import FlightListItem from "../../components/FlightListItem";
import Layout, {
    FlightListBreadcrumbContainer,
    Title
} from "../../components/Layout/Layout";
import LoadMoreButton from "../../components/LoadMoreButton";
import { useUser } from "../../contexts/user_provider";
import { getFirebaseFirestore } from "../../corvusFirebase.js";
import urls from "../../urls.js";

function FlightList(props) {
    const { getUserIsAdmin } = useUser();

    return (
        <>
            {props.flights?.length === 0 && (
                <Alert variant="primary" className="mt-0 m-4">
                    No flights found
                </Alert>
            )}
            {props.flights.length > 0 && (
                <ListGroup variant='flush'>
                    {props.flights.map((flight) => (
                        <FlightListItem
                            key={flight.id}
                            to={urls.flight(props.organizationId, props.locationId, flight.id)}
                            flight={flight}
                            showActivity={true}
                            showFlights={true}
                            showMeasurements={true}
                        >
                            {flight?.id}
                            {getUserIsAdmin() && (
                                <> by {flight.drone.id}</>
                            )}
                        </FlightListItem>
                    ))}
                </ListGroup>
            )}
        </>
    );
}

export default function FlightListPage() { 
    const flightsPerPage = 50;
    const maxFlightsToLoad = 1000;

    const { organizationId, locationId } = useParams();
    const [flights, setFlights] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [flightsToLoad, setFlightsToLoad] = React.useState(flightsPerPage);

    React.useEffect(() => {
        console.log('Fetching flights ...');
        setIsLoading(true);

        let query = getFirebaseFirestore()
            .collection(`organizations/${organizationId}/locations/${locationId}/flights`)
            .orderBy('timestamp', 'desc');

        if (flightsToLoad >= 0) {
            query = query.limit(Math.min(flightsToLoad, maxFlightsToLoad));
        } else {
            query = query.limit(maxFlightsToLoad);
        }
    
        return query
            .onSnapshot((snapshot) => {
                console.log(`(Firestore) Receiving ... documents`);

                const newFlights = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });

                setFlights(newFlights);
                setIsLoading(false);
            });
    }, [organizationId, locationId, flightsToLoad, maxFlightsToLoad]);

    return (
        <>
            <Layout>
                <FlightListBreadcrumbContainer />
                <Card>
                    <Title title={
                        <>
                            <BackButton href={urls.location(organizationId, locationId)} />
                            Flights
                        </>
                    } />
                    <FlightList
                        organizationId={organizationId}
                        locationId={locationId}
                        flights={flights}
                    />
                </Card>
                <LoadMoreButton
                    disabled={false}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    load={() => {
                        setFlightsToLoad(flightsToLoad + flightsPerPage);
                    }}
                    showLoadAll={true}
                    loadAll={() => setFlightsToLoad(-1)}
                    text={`Showing ${flights.length} flights`}
                />
            </Layout>
        </>
    );
}