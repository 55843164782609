// TODO : display errors on this page

import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, { Title } from "../../components/Layout/Layout";
import { useUser } from "../../contexts/user_provider";
import urls from "../../urls.js";


export default function OrganizationListPage() {
    const { getUserIsAdmin } = useUser();
    const [organizations, setOrganizations] = React.useState([]);

    React.useEffect(() => {
        return api.getOrganizations(setOrganizations);
    }, []);

    return (
        <Layout>
            <Card>
                <Title title="Organizations" />
                <CorvusList>
                    {organizations &&
                        organizations.map((organization) => (
                            <CorvusListItem
                                className="p-0"
                                key={organization.id}
                                as={Link}
                                to={urls.organization(organization.id)}
                            >
                                <CorvusListItemText>
                                    {organization.name}
                                </CorvusListItemText>
                            </CorvusListItem>
                        ))}
                </CorvusList>
            </Card>
        </Layout>
    );
}
