import React from "react";
import { Badge, Card, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaChevronRight, FaClock } from "react-icons/fa";
import * as api from "../../api/api.jsx";
import Layout, {
    Title
} from "../../components/Layout/Layout.jsx";
import urls from "../../urls.js";
import moment from 'moment-timezone';

var parser = require("cron-parser");
var cronstrue = require("cronstrue/i18n");

function RoutineList(props) {
    const [organizations, setOrganizations] = React.useState([]);

    React.useEffect(() => {
        return api.getOrganizations(setOrganizations);
    }, []);

    function getOrganizationName(organizationId) {
        const organization = organizations.find((o) => o.id === organizationId);
        return organization ? organization.name : 'Unknown';
    }

    function describeCronJob(cronString) {
        return cronstrue.toString(cronString, {
            throwExceptionOnParseError: false,
            locale: "en",
            use24HourTimeFormat: true,
            verbose: true
        });
    }

    if (props.routines.length <= 0) {
        return null;
    }

    return (
        <>
            {props.routines.filter(routine => routine.enabled).sort((a, b) => {
                const aNext = parser.parseExpression(a.interval).next().toDate();
                const bNext = parser.parseExpression(b.interval).next().toDate();
                return aNext - bNext;
            }).map(routine => {
                let formattedInterval = '';
                try {
                    const nextInterval = parser.parseExpression(routine.interval).next().toString();
                    formattedInterval = moment(nextInterval).format('ddd HH:mm');
                } catch (error) {
                    console.error(`Error parsing interval for routine ${routine.id}:`, error);
                    formattedInterval = 'Invalid Interval';
                }
                return (
                    <ListGroup.Item
                        className="px-4 py-2 d-flex justify-content-between align-items-start"
                        key={props.key}
                        as={Link}
                        to={urls.routine(routine.organizationId, routine.locationId, routine.id)}
                        action
                        title={describeCronJob(routine.interval)}
                    >
                        <div>
                            <FaClock className='me-4 text-primary' style={{marginTop: "-2px"}} />
                            {routine.name} @ {getOrganizationName(routine.organizationId)} by {routine.droneId}
                        </div>
                        <div>
                            <Badge pill bg='primary' className='me-1'>
                                <FaClock className='me-2' style={{marginTop: "-2px"}}/>
                                {formattedInterval}
                            </Badge>
                            <FaChevronRight style={{ color: "#b9b9b9" }} />
                        </div>
                    </ListGroup.Item>
                )
            })}
        </>
    );
}

function RoutineByDateList(props) {
    const [routinesByDate, setRoutinesByDate] = React.useState({});

    React.useEffect(() => {
        const newRoutinesByDate = {};
        
        // Process routines
        props.routines.forEach((routine) => {
            if (routine.enabled) {
                try {
                    const nextOccurrenceDate = parser.parseExpression(routine.interval).next().toDate();
                    const date = nextOccurrenceDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
                    
                    if (!newRoutinesByDate[date]) {
                        newRoutinesByDate[date] = [];
                    }
                    newRoutinesByDate[date].push(routine);
                } catch (error) {
                    console.error(`Error parsing interval for routine ${routine.id}:`, error);
                }
            }
        });

        setRoutinesByDate(newRoutinesByDate);
    }, [props.routines]);

    return (
        <>
            {Object.keys(routinesByDate).sort().map((date) => (
                <Card className='mt-4'>
                    <Card.Header className="px-4 d-flex justify-content-between align-items-start">
                        <div><b>{date}</b></div>
                        <div className='text-muted'>
                            <small>
                                {routinesByDate[date].length} routine(s)
                            </small>
                        </div>
                    </Card.Header>
                    <RoutineList routines={routinesByDate[date]} />
                </Card>
            ))}
        </>
    );

}

export default function AdminRoutineListPage() {
    const [routines, setRoutines] = React.useState([]);

    React.useEffect(() => {
        return api.subscribeAdminRoutines(setRoutines);
    }, []);

    return (
        <>
            <Layout>
                <Card>
                    <Title title='Routines'>
                    </Title>
                </Card>
                <RoutineByDateList routines={routines} />
            </Layout>
        </>
    );
}
