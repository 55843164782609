import React from "react";
import { Alert, Badge, Button, Card } from "react-bootstrap";
import { useUser } from "../../contexts/user_provider";
import { FaCube, FaServer, FaPlus } from "react-icons/fa";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import BackButton from "../../components/BackButton";
import {
    CorvusList,
    CorvusListItem,
    CorvusListItemText
} from "../../components/CorvusList";
import Layout, {
    DroneListBreadcrumbContainer,
    Title
} from "../../components/Layout/Layout";
import urls from "../../urls.js";


export default function DroneListPage() {
    const history = useHistory();
    const { organizationId, locationId } = useParams();
    const [drones, setDrones] = React.useState([]);
    const { getUserIsAdmin } = useUser();

    if (!locationId || locationId === "undefined") {
        history.push(urls.locations(organizationId));
    }

    // TODO : subscribe to drone feedback and implement this ...
    // function droneIsOnline(drone, droneFeedback) { // TODO : make dry
    //     if (!drone) return false;
    //     // TODO : fake is not a proper name isFake would be much better already..
    //     if (drone.fake) return true; // Fake drones should always be online
    //     if (!droneFeedback) return false;
    //     if (!droneFeedback.lastUpdate) return false;

    //     return api.droneIsOnline(droneFeedback.lastUpdate);
    // }

    React.useEffect(() => {
        if (organizationId && locationId) {
            api.getDroneConfigs(organizationId, locationId, setDrones);
        }
    }, [organizationId, locationId]);

    return (
        <>
            <Layout>
                <DroneListBreadcrumbContainer />
                <Card>
                    <Title title={
                        <>
                            <BackButton href={urls.location(organizationId, locationId)} />
                            Drones
                        </>
                    }>
                        {getUserIsAdmin() &&
                            organizationId === 'xu9pkLgk4tQ1dOnfyqVM' &&
                            locationId === 'dMaW7ev8fg5GhoVJkqLT' &&
                            <Button
                                variant="outline-success"
                                as={Link}
                                to={urls.droneCreate(organizationId, locationId)}
                            >
                                <FaPlus className="me-2" />
                                New Drone
                            </Button>
                        }
                    </Title>
                    {drones.length === 0 && (
                        <Alert variant="primary" className="mx-4 mb-4">
                            No drones
                        </Alert>
                    )}
                    {drones.length > 0 && (
                        <CorvusList>
                            {drones.map((drone) => (
                                <CorvusListItem
                                    className="p-0"
                                    key={drone.id}
                                    as={Link}
                                    to={urls.locationDrone(organizationId, locationId, drone.id)}
                                >
                                    <CorvusListItemText>
                                        {/* {
                                            api.droneIsOnline(drone.droneFeedback) && (
                                                <FaCircle className="text-success me-4" />
                                            )
                                        }
                                        {
                                            !api.droneIsOnline(drone.droneFeedback) && (
                                                <FaCircle className="text-danger me-4" />
                                            )
                                        } */}
                                        Drone <span className='mx-1' style={{ fontFamily: 'monospace' }}>{drone.id}</span>
                                        {/* Drone <code>{drone.id}</code> */}
                                        {drone.augmentation === 'EMULATION' && (
                                            <Badge bg="info" className='ms-2'>
                                                <FaServer />
                                            </Badge>
                                        )}
                                        {drone.augmentation === 'SIMULATION' && (
                                            <Badge bg="info" className='ms-2'>
                                                <FaCube />
                                            </Badge>
                                        )}
                                    </CorvusListItemText>
                                </CorvusListItem>
                            ))}
                        </CorvusList>)}
                </Card>
            </Layout>
        </>
    );
}
